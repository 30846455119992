var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v("Viewing\n"),
    _c("strong", [_vm._v(_vm._s(_vm.numberOfFirstRecordOnPage))]),
    _vm._v("\n-\n"),
    _c("strong", [_vm._v(_vm._s(_vm.numberOfLastRecordOnPage))]),
    _vm._v("\nof\n"),
    _c("strong", [_vm._v(_vm._s(_vm.totalRecords))]),
    _vm._v("\nitems found"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }