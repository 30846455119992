var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _vm.show
      ? _c("div", [
          _c("label", { staticClass: "control-label" }, [_vm._v("Subsites")]),
          _c(
            "div",
            [
              _c("DropdownCheckboxes", {
                staticClass: "block",
                attrs: {
                  label: "Subsites",
                  value: _vm.value,
                  options: _vm.subsiteOptions,
                },
                on: { input: _vm.emitInput },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }