var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.save.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("Label", { attrs: { text: "Title" } }),
                _c("Input", {
                  model: {
                    value: _vm.squeue.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.squeue, "title", $$v)
                    },
                    expression: "squeue.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("Label", { attrs: { text: "Code" } }),
                _c("Input", {
                  attrs: { disabled: _vm.squeue.system_record },
                  model: {
                    value: _vm.squeue.slug,
                    callback: function ($$v) {
                      _vm.$set(_vm.squeue, "slug", $$v)
                    },
                    expression: "squeue.slug",
                  },
                }),
              ],
              1
            ),
            _c("LabelledCheckbox", {
              attrs: { label: "Enabled" },
              model: {
                value: _vm.squeue.enabled,
                callback: function ($$v) {
                  _vm.$set(_vm.squeue, "enabled", $$v)
                },
                expression: "squeue.enabled",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-5" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("Label", { attrs: { text: "Color" } }),
              _c(
                "button",
                {
                  staticClass: "color-button btn btn-block",
                  style: { "background-color": _vm.color },
                  attrs: { type: "button" },
                  on: { click: _vm.toggleColorPicker },
                },
                [
                  _vm._v(" "),
                  _vm.isDefaultColor
                    ? _c("span", { staticClass: "text-muted" }, [
                        _vm._v("click to set"),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.pickingColor
                ? _c("ColorPicker", {
                    staticClass: "color-picker",
                    model: {
                      value: _vm.color,
                      callback: function ($$v) {
                        _vm.color = $$v
                      },
                      expression: "color",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("Label", { attrs: { text: "Auto-triggered web export" } }),
              _c("SelectInput", {
                attrs: { options: _vm.WEB_EXPORT_OPTIONS },
                model: {
                  value: _vm.squeue.trigger_web_export,
                  callback: function ($$v) {
                    _vm.$set(_vm.squeue, "trigger_web_export", $$v)
                  },
                  expression: "squeue.trigger_web_export",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v("Save")]
            ),
            _c(
              "router-link",
              {
                staticClass: "btn btn-link text-muted",
                attrs: { to: "/admin/squeues" },
              },
              [_vm._v("Cancel")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-5 text-right" }, [
          _vm.squeue.id && !_vm.squeue.system_record
            ? _c(
                "a",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button" },
                  on: { click: _vm.destroy },
                },
                [_c("i", { staticClass: "fa fa-trash text-muted" })]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }