var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", { staticClass: "text-center" }, [
      _c(
        "a",
        {
          staticClass: "toggle-button",
          on: {
            click: function ($event) {
              return _vm.toggleFavorite()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-lg",
            class: { "fa-star": _vm.favorite, "fa-star-o": !_vm.favorite },
          }),
        ]
      ),
    ]),
    _c("td", [_vm._v(_vm._s(_vm.title))]),
    _c("td", [
      _c("a", { attrs: { href: _vm.query, title: _vm.query } }, [
        _vm._v(_vm._s(_vm.truncatedQuery)),
      ]),
    ]),
    _c("td", [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.toggleDefault()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa",
            class: {
              "fa-check-square-o": _vm.userDefault,
              "fa-square-o": !_vm.userDefault,
            },
          }),
        ]
      ),
    ]),
    _vm.hotQueues
      ? _c("td", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.toggleHotQueue()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-check-square-o": _vm.hotQueue,
                  "fa-square-o": !_vm.hotQueue,
                },
              }),
            ]
          ),
        ])
      : _vm._e(),
    _c("td", [
      _c(
        "a",
        {
          staticClass: "btn btn-danger btn-xs",
          on: {
            click: function ($event) {
              return _vm.destroy()
            },
          },
        },
        [_c("i", { staticClass: "fa fa-trash-o" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }