var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "fa",
    class: {
      "fa-check-square-o": _vm.checked,
      "fa-square-o": !_vm.checked,
      ..._vm.sizeObject,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }