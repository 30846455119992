<template lang="pug">
LocationForm
</template>

<script>
  import LocationForm from '../shared/form.vue'

  export default {
    components: {
      LocationForm
    }

    // TODO: reset on mount with loader
  }
</script>
