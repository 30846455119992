var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.files.length === 0
        ? [
            _c("div", { staticClass: "alert alert-info" }, [
              _vm._v("No files were found in this folder"),
            ]),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row file-grid" },
        [
          _c(
            "FileDropOverlay",
            { on: { drop: _vm.uploadNewFiles } },
            [
              _vm._l(_vm.orderedFiles, function (file) {
                return _c("File", { key: file.name, attrs: { file: file } })
              }),
              _vm.showNewFolder
                ? [
                    _c("div", { staticClass: "col-sm-6 col-md-4 col-sg-3" }, [
                      _c("div", { staticClass: "thumbnail" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-link btn-lg show-on-hover cancel",
                            on: {
                              click: function ($event) {
                                return _vm.cancelNewFolder()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-ban text-warning" })]
                        ),
                        _c(
                          "div",
                          { staticClass: "thumb file text-center text-muted" },
                          [_c("i", { staticClass: "fa fa-folder-o" })]
                        ),
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.createNewFolder()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newFolderName,
                                    expression: "newFolderName",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeholder: "Folder name",
                                  autofocus: "",
                                },
                                domProps: { value: _vm.newFolderName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.newFolderName = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn btn-success input-group-addon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.createNewFolder()
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-save" })]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "clearfix" }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row buttons text-right" }, [
        _c(
          "label",
          {
            staticClass: "btn btn-default btn-file",
            on: { change: _vm.newFilesHandler },
          },
          [
            _vm.loading
              ? _c("Loader", { attrs: { stacked: false } })
              : _vm._e(),
            !_vm.loading ? _c("i", { staticClass: "fa fa-file-o" }) : _vm._e(),
            _c("input", { attrs: { type: "file", multiple: "" } }),
            _vm._v("\nNew Files"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { disabled: this.showNewFolder },
            on: {
              click: function ($event) {
                return _vm.addNewFolder()
              },
            },
          },
          [_c("i", { staticClass: "fa fa-folder-o" }), _vm._v("\nNew Folder")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }