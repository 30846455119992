var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-manager" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-md-9" },
          [
            _c("FilterBar"),
            _c("hr"),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "FileDropOverlay",
                  { on: { drop: _vm.dropHandler } },
                  [
                    _c(
                      "LoaderOverlay",
                      { attrs: { loading: _vm.loading } },
                      [
                        _vm._l(_vm.media, function (medium) {
                          return _c(
                            "div",
                            {
                              staticClass: "file-grid",
                              on: {
                                click: function ($event) {
                                  return _vm.toggle(medium.id)
                                },
                              },
                            },
                            [
                              _c("Medium", {
                                attrs: {
                                  medium: medium,
                                  selected: _vm.selectedMediaIds.includes(
                                    medium.id
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        _vm.media.length == 0
                          ? _c(
                              "div",
                              { staticClass: "col-sm-12 text-center" },
                              [
                                _c("div", { staticClass: "alert alert-info" }, [
                                  _vm._v("No results were found"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "clearfix" }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "nav",
              { staticClass: "text-center" },
              [
                _c("Pagination", {
                  attrs: {
                    current: _vm.page.current,
                    per: _vm.page.per,
                    "total-records": _vm.page.total_records,
                  },
                  on: { change: _vm.changePage },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("Sidebar", { staticClass: "col-md-3" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }