var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.authorized && _vm.configured
    ? _c(
        _vm.tag,
        {
          tag: "compntent",
          attrs: { type: "button" },
          on: { click: _vm.openDialog },
        },
        [
          _vm._t("default", function () {
            return [_vm._v("Google Drive")]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }