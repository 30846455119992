var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "richtext-editor" }, [
    _vm.label || _vm.showCounts
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("label", { staticClass: "control-label" }, [
              _vm.label ? _c("h4", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
            ]),
          ]),
          _vm.editor
            ? _c("div", { staticClass: "col-sm-6 counts text-right" }, [
                _c("span", { staticClass: "label-group" }, [
                  _c("span", { staticClass: "label label-default" }, [
                    _vm._v("Words"),
                  ]),
                  _c("span", { staticClass: "label label-info" }, [
                    _vm._v(_vm._s(_vm.wordCount)),
                  ]),
                ]),
                _vm._v("   "),
                _c("span", { staticClass: "label-group" }, [
                  _c("span", { staticClass: "label label-default" }, [
                    _vm._v("Inches"),
                  ]),
                  _c("span", { staticClass: "label label-info" }, [
                    _vm._v(_vm._s(_vm.inchCount) + '"'),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.editor
      ? _c(
          "div",
          { staticClass: "menus" },
          [
            _c("Toolbar", { attrs: { editor: _vm.editor } }),
            _c("TableMenu", {
              attrs: { editor: _vm.editor, changeBus: _vm.changeBus },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { ref: "contentWrapper", staticClass: "content-wrapper" },
      [_c("editor-content", { attrs: { editor: _vm.editor } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }