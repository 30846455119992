var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "placements" },
    [
      _vm._l(_vm.placementObjects, function ({ id, placement }) {
        return _c("Dropdown", {
          key: id,
          attrs: { value: placement, opened: _vm.openedId === id },
          on: {
            input: _vm.updatePlacement,
            opened: function ($event) {
              _vm.openedId = $event ? id : false
            },
          },
        })
      }),
      _c("div", { staticClass: "buttons text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-link",
            attrs: { type: "button", disabled: _vm.disabled },
            on: { click: _vm.addPlacement },
          },
          [_c("i", { staticClass: "fa fa-plus" }), _vm._v("\nAdd issue")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }