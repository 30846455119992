var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { tabindex: "-1", "data-backdrop": "static" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content media-manager mini" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.mediaSelectingStore.show = false
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-times" })]
                ),
                _c("h3", [_vm._v("Search Media")]),
              ]),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "form",
                    {
                      staticClass: "form-inline text-right",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.loadMedia({})
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "input-group input-group-lg" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.search,
                              expression: "search",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "search", placeholder: "Search" },
                          domProps: { value: _vm.search },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.search = $event.target.value
                            },
                          },
                        }),
                        _vm._m(0),
                      ]),
                    ]
                  ),
                  _c("br"),
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v("Select a file from the media library"),
                  ]),
                  _c("hr"),
                  _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _vm._l(
                          _vm.mediaSelectingStore.searchResults,
                          function (medium) {
                            return _c(
                              "div",
                              {
                                staticClass: "file-grid",
                                on: {
                                  click: function ($event) {
                                    return _vm.mediaSelectingStore.toggle(
                                      medium.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("Medium", {
                                  attrs: {
                                    medium: medium,
                                    selected: _vm.isSelected(medium.id),
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        _vm.mediaSelectingStore.searchResults.length == 0
                          ? _c(
                              "div",
                              { staticClass: "col-sm-12 text-center" },
                              [
                                _c("div", { staticClass: "alert alert-info" }, [
                                  _vm._v("No results were found"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 text-center" },
                        [
                          _c("Pagination", {
                            attrs: {
                              current: _vm.mediaSelectingStore.page.current,
                              per: _vm.perPage,
                              "total-records":
                                _vm.mediaSelectingStore.page.total_records,
                              "max-pages": 5,
                            },
                            on: { change: _vm.changePage },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link text-muted",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.mediaSelectingStore.show = false
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit", disabled: _vm.submitDisabled },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\nAdd Image"),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-group-btn" }, [
      _c(
        "button",
        { staticClass: "btn btn-default", attrs: { type: "submit" } },
        [_c("i", { staticClass: "fa fa-search" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }