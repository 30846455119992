var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      { staticClass: "table table-striped" },
      [
        _vm._m(0),
        _c(
          "draggable",
          {
            attrs: { tag: "tbody", handle: ".handle" },
            on: { change: _vm.reorder },
            model: {
              value: _vm.pages,
              callback: function ($$v) {
                _vm.pages = $$v
              },
              expression: "pages",
            },
          },
          [
            _vm._l(_vm.pages, function (page, index) {
              return [
                !page._destroy
                  ? _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: page.title,
                              expression: "page.title",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: page.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(page, "title", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: page.slug,
                              expression: "page.slug",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: page.slug },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(page, "slug", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: page.notes,
                              expression: "page.notes",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: page.notes },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(page, "notes", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [_vm._v(_vm._s(page.story_count))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "handle btn btn-default btn-sm",
                            staticStyle: { cursor: "move" },
                          },
                          [_c("i", { staticClass: "fa fa-sort" })]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger btn-sm",
                            on: {
                              click: function ($event) {
                                return _vm.deletePage(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4 text-left" }, [
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.addBlankPage } },
          [_c("i", { staticClass: "fa fa-plus" }), _vm._v("\nAdd Page")]
        ),
      ]),
      _c("div", { staticClass: "col-md-8 text-right" }, [
        _c(
          "button",
          { staticClass: "btn btn-link text-muted", on: { click: _vm.cancel } },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-success", on: { click: _vm.save } },
          [_c("i", { staticClass: "fa fa-save" }), _vm._v("\nSave")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Slug")]),
        _c("th", [_vm._v("Notes")]),
        _c("th", [_vm._v("Stories")]),
        _c("th", [_vm._v("Sort")]),
        _c("th", [_vm._v("Delete")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }