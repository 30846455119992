var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "list-group-item", class: _vm.styles },
    [
      _c("Checkbox", {
        staticClass: "right-check",
        model: {
          value: _vm.checked,
          callback: function ($$v) {
            _vm.checked = $$v
          },
          expression: "checked",
        },
      }),
      _c("i", { staticClass: "fa fa-newspaper-o" }),
      _vm._v("\n" + _vm._s(_vm.issue.title)),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }