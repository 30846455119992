var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "media-manager-upload" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "upload-file" } }, [_vm._v("File")]),
            _c("div", { staticClass: "file-input" }, [
              _c(
                "label",
                {
                  staticClass: "btn btn-default btn-file",
                  style: _vm.fileButtonStyles,
                },
                [
                  _c("i", { class: _vm.fileIconClasses }),
                  _c("input", {
                    ref: "fileInput",
                    attrs: { id: "upload-file", type: "file" },
                    on: {
                      change: function ($event) {
                        return _vm.fileChosen($event.target.files[0])
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "upload-buttons" },
                    [
                      _c("span", { staticClass: "btn btn-default" }, [
                        _c("i", { staticClass: "fa fa-desktop" }),
                      ]),
                      _c(
                        "GooglePickerButton",
                        {
                          staticClass: "btn btn-default",
                          on: {
                            "download-started": function ($event) {
                              _vm.loading = true
                            },
                            input: _vm.fileChosen,
                          },
                        },
                        [_c("i", { staticClass: "fa fa-google" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("span", { staticClass: "filename text-muted" }, [
                _vm._v(_vm._s(_vm.filename)),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("SqueueField", {
                model: {
                  value: _vm.squeueId,
                  callback: function ($$v) {
                    _vm.squeueId = $$v
                  },
                  expression: "squeueId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("SectionField", {
                model: {
                  value: _vm.sectionId,
                  callback: function ($$v) {
                    _vm.sectionId = $$v
                  },
                  expression: "sectionId",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "upload-credit" } }, [
              _vm._v("Credit"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.credit,
                  expression: "credit",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "upload-credit", type: "text" },
              domProps: { value: _vm.credit },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.credit = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "upload-cutline" } }, [
                _vm._v("Cutline"),
              ]),
              _c("RichtextEditor", {
                attrs: {
                  placeholder: "Cutline",
                  classes: "form-control-sm",
                  editorId: "upload-cutline",
                },
                model: {
                  value: _vm.cutline,
                  callback: function ($$v) {
                    _vm.cutline = $$v
                  },
                  expression: "cutline",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { type: "submit" },
              },
              [
                _c("i", { staticClass: "fa fa-cloud-upload" }),
                _vm._v("\n\n" + _vm._s(this.isNew ? "Upload" : "Update")),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }