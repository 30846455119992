var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "btn-group" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-default",
        class: { active: _vm.isActive("_score") },
        attrs: { type: "button", disabled: !_vm.relevanceSortAvailable },
        on: {
          click: function ($event) {
            return _vm.sortBy("_score")
          },
        },
      },
      [
        _c("i", { staticClass: "fa fa-sort-amount-desc" }),
        _vm._v("\n\nRelevance"),
      ]
    ),
    _c(
      "button",
      {
        staticClass: "btn btn-default",
        class: { active: _vm.isActive("pub_date") },
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.sortBy("pub_date")
          },
        },
      },
      [
        _c("i", { staticClass: "fa fa-sort-numeric-desc" }),
        _vm._v("\n\nPub date"),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }