var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "control-label" }, [_vm._v("Status")]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.onlinestatusId,
            expression: "onlinestatusId",
          },
        ],
        staticClass: "form-control",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.onlinestatusId = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v("Select an Online Status"),
        ]),
        _vm._l(_vm.onlinestatusesStore.onlinestatuses, function (onlineStatus) {
          return _c("option", { domProps: { value: onlineStatus.id } }, [
            _vm._v(_vm._s(onlineStatus.title)),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }