var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "story-medium" }, [
    _c("div", { staticClass: "media" }, [
      _c("div", { staticClass: "media-left" }, [
        _c(
          "div",
          {
            ref: "thumbnail",
            staticClass: "image",
            attrs: {
              title: _vm.mediaFileName,
              "data-toggle": "tooltip",
              "data-placement": "top",
            },
          },
          [
            _c("i", { staticClass: "fa fa-sort handle show-on-hover" }),
            _vm.fileType == "image"
              ? _c("img", { attrs: { src: _vm.small_thumb_url, width: "100" } })
              : _vm._e(),
            _vm.fileType != "image"
              ? _c("i", { staticClass: "fa fa-file" })
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "media-body" },
        [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _c("Input", {
                staticClass: "media-heading",
                attrs: { type: "text", placeholder: "Credit" },
                model: {
                  value: _vm.credit,
                  callback: function ($$v) {
                    _vm.credit = $$v
                  },
                  expression: "credit",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "btn btn-danger input-group-addon",
                  attrs: { type: "button" },
                  on: { click: _vm.deleteStoryMedia },
                },
                [_c("i", { staticClass: "fa fa-trash" })]
              ),
            ],
            1
          ),
          _c("RichtextEditor", {
            attrs: { placeholder: "Cutline", classes: "form-control-sm" },
            model: {
              value: _vm.cutline,
              callback: function ($$v) {
                _vm.cutline = $$v
              },
              expression: "cutline",
            },
          }),
          _c("div", [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("LabelledCheckbox", {
                  staticClass: "form-row-for-print",
                  attrs: { label: "For print" },
                  model: {
                    value: _vm.for_print,
                    callback: function ($$v) {
                      _vm.for_print = $$v
                    },
                    expression: "for_print",
                  },
                }),
                _c("LabelledCheckbox", {
                  staticClass: "form-row-for-web",
                  attrs: { label: "For web" },
                  model: {
                    value: _vm.for_web,
                    callback: function ($$v) {
                      _vm.for_web = $$v
                    },
                    expression: "for_web",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }