var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [_vm._v(_vm._s(_vm.location.title))]),
    _c("td", [_vm._v(_vm._s(_vm.location.address_street1))]),
    _c("td", [_vm._v(_vm._s(_vm.location.city))]),
    _c("td", [_vm._v(_vm._s(_vm.location.state))]),
    _c("td", [_vm._v(_vm._s(_vm.location.zip))]),
    _c("td", { staticClass: "text-right" }, [
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-default",
              attrs: { to: `/locations/${_vm.location.id}/edit` },
            },
            [_c("i", { staticClass: "fa fa-pencil" })]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-danger",
              attrs: { disabled: _vm.deleting },
              on: {
                click: function ($event) {
                  return _vm.destroy()
                },
              },
            },
            [
              _vm.deleting
                ? _c("Loader", { attrs: { stacked: false } })
                : _vm._e(),
              !_vm.deleting
                ? _c("i", { staticClass: "fa fa-trash" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }