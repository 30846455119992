var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table table-striped" }, [
    _vm._m(0),
    _c(
      "tbody",
      _vm._l(_vm.locations, function (location) {
        return _c("Row", { key: location.id, attrs: { location: location } })
      }),
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Address")]),
        _c("th", [_vm._v("City")]),
        _c("th", [_vm._v("State")]),
        _c("th", [_vm._v("Zip")]),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }