import { defineStore } from 'pinia'
import axios from 'axios'
import { withIDKeys } from '../utils/state_helpers'
import { del } from 'vue'
import store from '../store'

import { getDefaultPaginationState } from '../store/shared/pagination'

export const useLocationsStore = defineStore('locations', {
  state: () => ({
    ...getDefaultPaginationState(),
    searchResults: {},
  }),

  actions: {
    async search({ search, page, perPage }) {
      return axios.get('/api/internal/locations', {
        params: { search, page, per_page: perPage }
      }).then(({ data: { locations, meta: { page }} }) => {
        this.searchResults = withIDKeys(locations)
        this.page = page
      })
    },

    async save() {

    },

    async create() {
      return axios.post('/api/internal/locations', {
        location: {

        }
      })
    },

    async destroy({ id }) {
      return axios.delete(`/api/internal/locations/${id}`)
        .then(() => {
          del(this.searchResults, id)
          store.dispatch('messages/addNotice', 'Location deleted')
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    }
  }
})
