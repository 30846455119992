var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { key: _vm.story.updated_at, class: { changed: _vm.changed } },
    [
      _vm.showCol("color") ? _c("td", { style: _vm.colorColColor }) : _vm._e(),
      _vm.showCol("checkbox") &&
      !_vm.currentUserStore.currentUser.role_is_limited
        ? _c(
            "td",
            { style: _vm.checkboxColColor },
            [
              _vm.locked ? _c("i", { staticClass: "fa fa-lock" }) : _vm._e(),
              !_vm.locked
                ? _c("CheckBox", {
                    attrs: { "unique-key": _vm.story.id },
                    model: {
                      value: _vm.storySelected,
                      callback: function ($$v) {
                        _vm.storySelected = $$v
                      },
                      expression: "storySelected",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showCol("id") ? _c("td", [_vm._v(_vm._s(_vm.story.id))]) : _vm._e(),
      _vm.showCol("pub_date")
        ? _c("td", { attrs: { title: _vm.story.pub_date } }, [
            _vm._v(_vm._s(_vm.story.pub_date)),
          ])
        : _vm._e(),
      _vm.showCol("slug")
        ? _c("td", [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToShow()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.story.slug))]
            ),
          ])
        : _vm._e(),
      _vm.showCol("name")
        ? _c("td", { domProps: { innerHTML: _vm._s(_vm.story.name) } })
        : _vm._e(),
      _vm.showCol("sections")
        ? _c("td", [_vm._v(_vm._s(_vm.story.sections.join(", ")))])
        : _vm._e(),
      _vm.showCol("web_categories")
        ? _c("td", [_vm._v(_vm._s(_vm.story.web_categories.join(", ")))])
        : _vm._e(),
      _vm.showCol("words")
        ? _c("td", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.story.words) + " / " + _vm._s(_vm.story.inches)),
          ])
        : _vm._e(),
      _vm.showCol("media")
        ? _c("td", { staticClass: "text-center" }, [
            _vm.story.media_count > 0
              ? _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToShow({ hash: "media" })
                      },
                    },
                  },
                  [
                    _vm.story.image_count > 0
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-file-image-o" }),
                          _vm._v("\n\n" + _vm._s(_vm.story.image_count)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.nonImageCount > 0
                      ? _c("span", [
                          _c("i", { staticClass: "fa fa-file-o" }),
                          _vm._v("\n\n" + _vm._s(_vm.nonImageCount)),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.showCol("exported")
        ? _c(
            "td",
            [_c("CheckboxIcon", { attrs: { checked: _vm.story.exported } })],
            1
          )
        : _vm._e(),
      _vm.showCol("squeue")
        ? _c(
            "td",
            [
              _c("SqueueField", {
                attrs: {
                  value: _vm.story.squeue_id,
                  required: true,
                  "show-label": false,
                  disabled: _vm.locked,
                },
                on: { input: _vm.squeueIdChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showCol("actions")
        ? _c("td", { staticClass: "actions text-center" }, [
            _c(
              "div",
              { staticClass: "btn-group action-buttons" },
              [
                _c("EditButton", { attrs: { "story-id": _vm.story.id } }),
                _c(
                  "MoreButton",
                  _vm._g(
                    {
                      attrs: {
                        "story-id": _vm.story.id,
                        "story-name": _vm.story.name,
                        locked: _vm.locked,
                      },
                    },
                    _vm.$listeners
                  )
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }