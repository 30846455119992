var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      { staticClass: "original" },
      [
        _c("TextInput", {
          model: {
            value: _vm.rule.original,
            callback: function ($$v) {
              _vm.$set(_vm.rule, "original", $$v)
            },
            expression: "rule.original",
          },
        }),
      ],
      1
    ),
    _c(
      "td",
      { staticClass: "replacement" },
      [
        _c("TextOrCharacterInput", {
          attrs: { rows: 1 },
          model: {
            value: _vm.rule.replacement_start,
            callback: function ($$v) {
              _vm.$set(_vm.rule, "replacement_start", $$v)
            },
            expression: "rule.replacement_start",
          },
        }),
      ],
      1
    ),
    _c("td", { staticClass: "actions" }, [
      _vm.isDirty
        ? _c(
            "button",
            {
              staticClass: "btn btn-success",
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [
              _vm.saving
                ? _c("Loader", { attrs: { stacked: false } })
                : _vm._e(),
              !_vm.saving ? _c("i", { staticClass: "fa fa-save" }) : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.isDirty
        ? _c(
            "button",
            {
              staticClass: "btn btn-danger",
              on: {
                click: function ($event) {
                  return _vm.destroy()
                },
              },
            },
            [
              _vm.deleting
                ? _c("Loader", { attrs: { stacked: false } })
                : _vm._e(),
              !_vm.deleting
                ? _c("i", { staticClass: "fa fa-trash" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }