var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    !_vm.disabled
      ? _c(
          "a",
          {
            staticClass: "sort_link",
            class: { asc: _vm.asc, desc: _vm.desc },
            on: { click: _vm.sort },
          },
          [_vm._v(_vm._s(_vm.text))]
        )
      : _vm._e(),
    _vm.disabled
      ? _c("span", { staticClass: "sort_link" }, [_vm._v(_vm._s(_vm.text))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }