var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "list-group-item placement" },
    [
      _vm._t("default"),
      _c("CheckboxIcon", { attrs: { checked: !!(_vm.issueId || _vm.pageId) } }),
      _vm._v(" "),
      _vm.issueId || _vm.pageId
        ? _c("strong", [_vm._v(_vm._s(_vm.publicationName))])
        : _c("span", { staticClass: "text-muted" }, [_vm._v("None selected")]),
      _vm._v(" "),
      _vm.issueId
        ? [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v(" "),
            _c("i", { staticClass: "fa fa-newspaper-o" }),
            _vm._v("\n" + _vm._s(_vm.issueTitle) + "\n\n"),
          ]
        : _vm._e(),
      _vm.pageId
        ? [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v(" "),
            _c("i", { staticClass: "fa fa-file-o" }),
            _vm._v("\n\n" + _vm._s(_vm.pageTitle)),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }