<template lang="pug">
.form-group
  template(v-if="anyExportFormats")
    Label(text="Export Fields")
    DropdownCheckboxes.block(label="Export formats" v-model="exportFormatIds" :options="exportFormatsStore.fieldsOnlyAsObject")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import { mapStores } from 'pinia'
  import { useExportFormatsStore } from '../../../stores/export_formats'

  import DropdownCheckboxes from '../../../components/dropdown_checkboxes.vue'
  import Label from '../../../components/shared/label.vue'

  export default {
    components: {
      DropdownCheckboxes,
      Label
    },

    computed: {
      ...mapStores(useExportFormatsStore),

      exportFormatIds: sync('story/export_format_ids'),

      anyExportFormats() {
        return Object.keys(this.exportFormatsStore.allAsObject).length > 0
      }
    },

    async mounted() {
      await this.exportFormatsStore.fetchOnce()
    }
  }
</script>

<style>
  .default-format {
    width: 50%;
    float: left;
    font-size: 1.2em;
    margin: 2px 0;
    opacity: 0.8;
  }
</style>
