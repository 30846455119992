var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      [
        _c(
          "StorySelect",
          {
            model: {
              value: _vm.selectedStoryId,
              callback: function ($$v) {
                _vm.selectedStoryId = $$v
              },
              expression: "selectedStoryId",
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-block",
                attrs: { slot: "footer" },
                on: {
                  click: function ($event) {
                    return _vm.attach()
                  },
                },
                slot: "footer",
              },
              [
                _c("i", { staticClass: "fa fa-paperclip" }),
                _vm._v("\nAttach to Story"),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c("p"),
    _c("div", { staticClass: "btn-group btn-group-justified" }, [
      _c(
        "a",
        { staticClass: "btn btn-default", attrs: { href: _vm.downloadPath } },
        [_c("i", { staticClass: "fa fa-cloud-download" }), _vm._v("\nDownload")]
      ),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          { staticClass: "btn btn-danger", on: { click: _vm.destroy } },
          [_c("i", { staticClass: "fa fa-trash" }), _vm._v("\nDelete")]
        ),
      ]),
    ]),
    _c("p"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }