var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    [
      _c("LabelledValue", {
        attrs: { label: "Publication", value: _vm.issue.publication.name },
      }),
      _c("LabelledValue", { attrs: { label: "Slug", value: _vm.issue.slug } }),
      _c("LabelledValue", {
        attrs: { label: "Publish date", value: _vm.issue.publish_on },
      }),
      _c("LabelledEditableValue", {
        attrs: { label: "Notes", "multi-line": true },
        on: { blur: _vm.save },
        model: {
          value: _vm.notes,
          callback: function ($$v) {
            _vm.notes = $$v
          },
          expression: "notes",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }