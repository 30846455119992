var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("JwPagination", {
    ref: "jwPagination",
    attrs: {
      items: _vm.pages,
      pageSize: 1,
      initialPage: _vm.current,
      maxPages: _vm.maxPages,
    },
    on: { changePage: _vm.onChangePage },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }