import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useLocationStore = defineStore('location', {
  state: () => ({
    location: {
      id:  null,
      title: null,
      address_street1: null,
      address_street2: null,
      city: null,
      state: null,
      zip: null,
      phone1: null,
      phone2: null,
      email: null,
      fax: null,
      website: null,
      description: null,
    }
  }),

  actions: {
    async fetch({ locationId }) {
      return axios.get(`/api/internal/locations/${locationId}`)
        .then(({ data: { location } }) => {
          this.location = location
        })
    },

    async update() {
      // TODO
    }
  }
})
