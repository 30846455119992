var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "locations" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("header", [
            _c(
              "div",
              { staticClass: "pull-right" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { to: "/locations/new" },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v("\nNew Location"),
                  ]
                ),
              ],
              1
            ),
            _c("h1", [_vm._v("Locations")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("section", { staticClass: "well well-sm top-section" }, [
            _c(
              "form",
              {
                staticClass: "inline-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.fetchSearchResults.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "search",
                      placeholder: "Search",
                      title: "Search",
                    },
                    domProps: { value: _vm.search },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _c("span", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-prominent",
                        attrs: { type: "button" },
                        on: { click: function ($event) {} },
                      },
                      [_vm._v("Search")]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.locations.length > 0
        ? _c("Table", { attrs: { locations: _vm.locations } })
        : _vm._e(),
      _vm.locations.length < 1
        ? [
            _c("div", { staticClass: "alert alert-info" }, [
              _vm._v("No locations were found"),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }