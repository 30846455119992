var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ol",
    { staticClass: "breadcrumb" },
    [
      _c(
        "li",
        { class: { active: _vm.atRoot } },
        [
          _vm.atRoot
            ? [_vm._v(_vm._s(_vm.resourceSlug))]
            : [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goToIndex(0)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.resourceSlug))]
                ),
              ],
        ],
        2
      ),
      _vm._l(_vm.currentPathParts, function (part, index) {
        return _c(
          "li",
          { key: part, class: { active: _vm.current(index) } },
          [
            _vm.current(index)
              ? [_vm._v(_vm._s(part))]
              : [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goToIndex(index + 1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(part))]
                  ),
                ],
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }