var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.loading
        ? [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: _vm.type,
                  placeholder: _vm.placeholder,
                  disabled: true,
                  readonly: true,
                },
                domProps: { value: _vm.value },
                on: { change: _vm.change, blur: _vm.blur },
              }),
              _c(
                "div",
                { staticClass: "input-group-addon" },
                [_c("Loader", { attrs: { stacked: false } })],
                1
              ),
            ]),
          ]
        : _vm._e(),
      !_vm.loading
        ? [
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: _vm.type,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                readonly: _vm.readonly,
              },
              domProps: { value: _vm.value },
              on: { change: _vm.change, blur: _vm.blur },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }