var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "control-label" }, [_vm._v("Web categories")]),
    _c(
      "div",
      [
        _c("DropdownCheckboxes", {
          staticClass: "block",
          attrs: {
            label: "Web categories",
            options: _vm.webCategoriesStore.allAsObject,
          },
          model: {
            value: _vm.webCategoryIds,
            callback: function ($$v) {
              _vm.webCategoryIds = $$v
            },
            expression: "webCategoryIds",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }