var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid" },
    [
      _c("header", [
        _c("div", { staticClass: "pull-right" }, [_c("NewStoryButton")], 1),
        _c("h1", [_vm._v("Stories")]),
      ]),
      _c("CommunityQPublishModal"),
      _c("StoryEmailModal"),
      _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.search()
              },
            },
          },
          [
            !_vm.currentUserStore.currentUser.role_is_limited
              ? _c("StoryFilterBar")
              : _vm._e(),
            _c(
              "StoryFileDrop",
              [
                _vm.stories.length === 0
                  ? _c("div", { staticClass: "alert alert-info" }, [
                      _vm._v("No stories were found"),
                    ])
                  : _c("StoriesTable", {
                      attrs: { columns: _vm.COLUMNS },
                      on: {
                        sort: function ($event) {
                          return _vm.sort()
                        },
                        "should-refresh": function ($event) {
                          return _vm.search()
                        },
                      },
                    }),
              ],
              1
            ),
            _c("StoryBatchOptions", { on: { change: _vm.reload } }),
            _c(
              "nav",
              { staticClass: "text-center" },
              [
                _c("PaginationDetails", {
                  attrs: {
                    current: _vm.page.current,
                    per: _vm.page.per,
                    "total-records": _vm.page.total_records,
                  },
                }),
                _c("Pagination", {
                  attrs: {
                    current: _vm.page.current,
                    per: _vm.page.per,
                    "total-records": _vm.page.total_records,
                  },
                  on: { change: _vm.changePage },
                }),
                _c("PerPageSelect", {
                  attrs: { value: _vm.page.per },
                  on: { input: _vm.changeDefaultPerPage },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }