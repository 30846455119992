var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.currentUserStore.currentUser.role_is_limited
    ? _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default dropdown-toggle",
            class: _vm.buttonClasses,
            attrs: { type: "button", "data-toggle": "dropdown" },
          },
          [_c("i", { staticClass: "fa fa-caret-down" })]
        ),
        _c(
          "ul",
          { staticClass: "dropdown-menu pull-right" },
          [
            _vm.show("duplicate")
              ? _c("li", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.cloneStory()
                        },
                      },
                    },
                    [_vm._v("Duplicate")]
                  ),
                ])
              : _vm._e(),
            _vm.show("delete")
              ? _c("li", [
                  _c(
                    "a",
                    {
                      class: { locked: _vm.locked },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.deleteStory()
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                ])
              : _vm._e(),
            _vm.show("divider")
              ? _c("li", { staticClass: "divider" })
              : _vm._e(),
            _c("li", { staticClass: "dropdown-header" }, [
              _vm._v("Export to..."),
            ]),
            _vm._l(_vm.exportFormatsStore.forStories, function (exportFormat) {
              return _c("li", [
                _c(
                  "a",
                  {
                    attrs: { target: "new" },
                    on: {
                      click: function ($event) {
                        return _vm.routeTo({
                          path: `export_formats/${exportFormat.id}`,
                        })
                      },
                    },
                  },
                  [_vm._v(_vm._s(exportFormat.name))]
                ),
              ])
            }),
            _c("li", [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.routeTo({ extension: "pdf" })
                    },
                  },
                },
                [_vm._v("PDF")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.routeTo({ extension: "zip" })
                    },
                  },
                },
                [_vm._v("Zip")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "email-export",
                  on: {
                    click: function ($event) {
                      return _vm.exportViaEmail()
                    },
                  },
                },
                [_vm._v("Email")]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "communityq-export",
                  class: { locked: _vm.locked },
                  on: {
                    click: function ($event) {
                      return _vm.publishToCommunityQ()
                    },
                  },
                },
                [_vm._v("Web")]
              ),
            ]),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }