var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header", [
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c(
              "router-link",
              { staticClass: "btn btn-default", attrs: { to: "/issues/new" } },
              [_vm._v("New Issue")]
            ),
          ],
          1
        ),
        _c("h1", [_vm._v("Issues")]),
      ]),
      _c(
        "LoaderOverlay",
        { attrs: { loading: _vm.loading } },
        [
          _vm.issues.length < 1
            ? [
                _c("div", { staticClass: "alert alert-info" }, [
                  _vm._v("No issues were found"),
                ]),
              ]
            : _vm._e(),
          _vm.issues.length > 0
            ? [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "publish_on" }, [
                        _vm._v("Publish Date"),
                      ]),
                      _c("th", { staticClass: "title" }, [_vm._v("Title")]),
                      _c("th", { staticClass: "slug" }, [_vm._v("Slug")]),
                      _c("th", { staticClass: "publication" }, [
                        _vm._v("Publication"),
                      ]),
                      _c("th", { staticClass: "story_count" }, [
                        _vm._v("Stories"),
                      ]),
                      _c("th", { staticClass: "current" }, [_vm._v("Current")]),
                      _c("th", { staticClass: "actions" }, [_vm._v(" ")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.issues, function (issue) {
                      return _c("Row", {
                        key: issue.id,
                        attrs: { issue: issue },
                      })
                    }),
                    1
                  ),
                ]),
                _c("Pagination", {
                  attrs: {
                    current: _vm.page.current,
                    per: _vm.page.per,
                    "total-records": _vm.page.total_records,
                  },
                  on: { change: _vm.changePage },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }