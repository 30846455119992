var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-group" },
    [
      _c("DatePicker", {
        ref: "picker",
        attrs: {
          name: _vm.name,
          "input-class": "form-control",
          format: "yyyy-MM-dd",
          "disabled-dates": _vm.disabledDates,
          placeholder: _vm.placeholder,
        },
        model: {
          value: _vm.pickedDate,
          callback: function ($$v) {
            _vm.pickedDate = $$v
          },
          expression: "pickedDate",
        },
      }),
      _c(
        "span",
        { staticClass: "input-group-btn" },
        [
          _vm.pickedDate
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.clearDate()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-times" })]
                ),
              ]
            : _vm._e(),
          !_vm.pickedDate
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.showCalendar()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-calendar" })]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }