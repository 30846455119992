var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v("My Settings")]),
    _c("hr"),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save()
          },
        },
      },
      [
        _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c("h2", [_vm._v("Contact Info")]),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "First name" } }),
                  _c("TextInput", {
                    model: {
                      value: _vm.user.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "first_name", $$v)
                      },
                      expression: "user.first_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Last name" } }),
                  _c("TextInput", {
                    model: {
                      value: _vm.user.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "last_name", $$v)
                      },
                      expression: "user.last_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Email" } }),
                  _c("TextInput", {
                    model: {
                      value: _vm.user.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Phone extension" } }),
                  _c("NumberInput", {
                    model: {
                      value: _vm.user.ext,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "ext", $$v)
                      },
                      expression: "user.ext",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Remote phone" } }),
                  _c("TextInput", {
                    model: {
                      value: _vm.user.remote_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "remote_phone", $$v)
                      },
                      expression: "user.remote_phone",
                    },
                  }),
                ],
                1
              ),
              _c("hr"),
              _c("h2", [_vm._v("Security")]),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Password (twice)" } }),
                  _c("TextInput", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                  _c("TextInput", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.user.password_confirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password_confirmation", $$v)
                      },
                      expression: "user.password_confirmation",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("h2", [_vm._v("Preferences")]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("Label", { attrs: { text: "Byline" } }),
                    _c("TextInput", {
                      model: {
                        value: _vm.user.byline,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "byline", $$v)
                        },
                        expression: "user.byline",
                      },
                    }),
                  ],
                  1
                ),
                _vm.hasManyPublications
                  ? _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("PublicationField", {
                          attrs: {
                            label: "Default publication",
                            required: false,
                          },
                          model: {
                            value: _vm.user.default_publication_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "default_publication_id", $$v)
                            },
                            expression: "user.default_publication_id",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("Label", {
                      attrs: { text: "NewsroomQ files location" },
                    }),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("span", { staticClass: "input-group-addon" }, [
                          _c("i", { staticClass: "fa fa-hdd-o" }),
                        ]),
                        _c("TextInput", {
                          attrs: {
                            placeholder: "/Volumes/PUBLIC/our.newsroomq.com/",
                          },
                          model: {
                            value: _vm.user.local_file_path,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "local_file_path", $$v)
                            },
                            expression: "user.local_file_path",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v(
                        "This should be the path to the directory where you access the NewsroomQ media files. Leaving this blank will disable links to folders."
                      ),
                    ]),
                  ],
                  1
                ),
                _c("hr"),
                _c("h2", [_vm._v("Email Me When")]),
                _c("LabelledCheckbox", {
                  attrs: { label: "I'm assigned a story" },
                  model: {
                    value: _vm.user.assignment_notifications,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "assignment_notifications", $$v)
                    },
                    expression: "user.assignment_notifications",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("hr"),
              _c("button", { staticClass: "btn btn-primary" }, [
                _vm._v("Save"),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }