var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-inline" },
    [
      _c("SelectInput", {
        attrs: { value: _vm.value || _vm.defaultLength, options: _vm.options },
        on: { input: _vm.changePerPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }