var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown dropdown-checkboxes" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-default btn-sm dropdown-toggle",
        attrs: { type: "button", "data-toggle": "dropdown" },
      },
      [
        _vm._v(_vm._s(_vm.selectedOrLabel) + "\n"),
        _c("span", { staticClass: "caret" }),
      ]
    ),
    _c(
      "ul",
      { staticClass: "dropdown-menu" },
      [
        _vm._l(_vm.options, function (val, optionName) {
          return _c("li", { key: val }, [
            _c("label", { staticClass: "radio-btn" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checked,
                    expression: "checked",
                  },
                ],
                attrs: { type: "checkbox", name: _vm.name },
                domProps: {
                  value: val,
                  checked: Array.isArray(_vm.checked)
                    ? _vm._i(_vm.checked, val) > -1
                    : _vm.checked,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.checked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = val,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checked = $$c
                    }
                  },
                },
              }),
              _vm._v("\n" + _vm._s(optionName)),
            ]),
          ])
        }),
        _vm.loading ? _c("li", [_c("Loader"), _c("br")], 1) : _vm._e(),
        _c("li", { staticClass: "text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-xs btn-default clear close-dropdown",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.clear()
                },
              },
            },
            [_vm._v("Clear")]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }