var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
    _c("img", {
      class: _vm.imgClass,
      attrs: { src: _vm.src, alt: _vm.alt },
      on: { load: _vm.onLoad },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }