var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "vue-page-story-show-root" },
    [
      _c("CommunityQPublishModal"),
      _c("StoryEmailModal"),
      _c(
        "nav",
        {
          staticClass: "story-header container",
          attrs: { "data-spy": "affix", "data-offset-top": "95" },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h1", [_vm._v(_vm._s(_vm.name))]),
            ]),
            _c("div", { staticClass: "col-md-6 text-right" }, [
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-prominent btn-info",
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left" }),
                      _vm._v("\nBack"),
                    ]
                  ),
                  _c("EditButton", {
                    attrs: { title: "Edit", "story-id": _vm.storyId },
                  }),
                  _c("MoreButton", {
                    attrs: { "story-id": _vm.storyId, "story-name": _vm.name },
                  }),
                  !_vm.spiked
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.spikeStory.apply(null, arguments)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("p", { staticClass: "server-path" }, [
                _c("i", { staticClass: "fa fa-hdd-o" }),
                _vm._v(" "),
                _vm.open_folder_url
                  ? _c("a", { attrs: { href: _vm.open_folder_url } }, [
                      _vm._v(_vm._s(_vm.content_path)),
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm.content_path))]),
              ]),
            ]),
            _c("div", { staticClass: "col-md-6 text-right" }, [
              _c(
                "p",
                {
                  staticClass: "updated-at",
                  attrs: { title: "Last modified" },
                },
                [
                  _c("i", { staticClass: "fa fa-clock-o" }),
                  _vm._v("\n" + _vm._s(_vm.lastUpdated)),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c("LabelledValue", { attrs: { label: "Slug", value: _vm.slug } }),
            _c("LabelledValue", {
              attrs: { label: "Overline", value: _vm.overline },
            }),
            _c("LabelledValue", {
              attrs: { label: "Headline", value: _vm.headline },
            }),
            _c("LabelledValue", {
              attrs: { label: "Deck head", value: _vm.drop_head },
            }),
            _c("LabelledValue", {
              attrs: { label: "Byline", value: _vm.byline, html: true },
            }),
            _c("LabelledValue", {
              attrs: {
                label: "Content",
                value: _vm.content,
                "multi-line": true,
                html: true,
              },
            }),
            _c("LabelledValue", {
              attrs: {
                label: "Tag line",
                value: _vm.tag,
                "multi-line": true,
                html: true,
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showInfobox,
                    expression: "showInfobox",
                  },
                ],
                staticClass: "panel panel-default",
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "panel-body" },
                  [
                    _c("LabelledValue", {
                      attrs: { label: "Headline", value: _vm.infobox_headline },
                    }),
                    _c("LabelledValue", {
                      attrs: {
                        label: "Text",
                        value: _vm.infobox_text,
                        "multi-line": true,
                        html: true,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPullQuote,
                    expression: "showPullQuote",
                  },
                ],
                staticClass: "panel panel-default",
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "panel-body" },
                  [
                    _c("LabelledValue", {
                      attrs: {
                        label: "Headline",
                        value: _vm.pullQuote.headline,
                      },
                    }),
                    _c("LabelledValue", {
                      attrs: {
                        label: "Quote",
                        value: _vm.pullQuote.quote,
                        "multi-line": true,
                      },
                    }),
                    _c("LabelledValue", {
                      attrs: {
                        label: "Attribution",
                        value: _vm.pullQuote.attribution,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCommunityQ,
                    expression: "showCommunityQ",
                  },
                ],
                staticClass: "panel panel-default",
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "panel-body" },
                  [
                    _c("LabelledCheckbox", {
                      attrs: { label: "Featured", value: _vm.featured },
                    }),
                    _c("LabelledCheckbox", {
                      attrs: { label: "Free", value: _vm.ispublic },
                    }),
                    _c("LabelledCheckbox", {
                      attrs: {
                        label: "Comments disabled",
                        value: _vm.comments_disabled,
                      },
                    }),
                    _c("LabelledValue", {
                      attrs: { label: "Headline", value: _vm.web_head },
                    }),
                    _c("LabelledValue", {
                      attrs: { label: "Slug", value: _vm.web_slug },
                    }),
                    _c("LabelledValue", {
                      attrs: {
                        label: "Summary",
                        value: _vm.web_summary,
                        "multi-line": true,
                        html: true,
                      },
                    }),
                    _c("LabelledValue", {
                      attrs: {
                        label: "Body",
                        value: _vm.web_body,
                        "multi-line": true,
                        html: true,
                      },
                    }),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("LabelledValue", {
                            attrs: {
                              label: "Status",
                              value: _vm.onlineStatusTitle,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("LabelledValue", {
                            attrs: { label: "Keywords", value: _vm.keywords },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._l(
                      Object.values(_vm.storyPublications),
                      function (storyPub) {
                        return _c("Publication", {
                          key: storyPub.id,
                          attrs: { "story-publication": storyPub },
                        })
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-5", attrs: { id: "media" } },
          [
            _c("h4", [_vm._v("Attachments")]),
            _vm._l(_vm.storyMedia, function (storyMedium) {
              return _c(
                "div",
                { key: storyMedium.id, staticClass: "story-medium" },
                [
                  !storyMedium.use_as_content_for
                    ? _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-left" }, [
                          storyMedium.medium.url
                            ? _c(
                                "a",
                                {
                                  staticClass: "image",
                                  attrs: {
                                    href: storyMedium.medium.url,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm.isImage(storyMedium)
                                    ? _c("img", {
                                        attrs: {
                                          src: storyMedium.medium
                                            .small_thumb_url,
                                          width: "100",
                                        },
                                      })
                                    : _c(
                                        "div",
                                        { staticClass: "well non-image-file" },
                                        [_c("i", { staticClass: "fa fa-file" })]
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "media-body" },
                          [
                            _c("LabelledValue", {
                              attrs: {
                                label: "Credit",
                                value: storyMedium.medium.credit,
                              },
                            }),
                            _c("LabelledValue", {
                              attrs: {
                                label: "Cutline",
                                value: storyMedium.medium.cutline,
                                "multi-line": true,
                                html: true,
                              },
                            }),
                            _c("div", { staticClass: "text-left" }, [
                              _c("a", {
                                staticClass: "media-filename text-muted",
                                attrs: { href: storyMedium.medium.url },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.mediaFileName(
                                      storyMedium.medium.filename
                                    )
                                  ),
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c("LabelledCheckbox", {
                                  attrs: {
                                    label: "For print",
                                    value: storyMedium.for_print,
                                  },
                                }),
                                _c("LabelledCheckbox", {
                                  attrs: {
                                    label: "For web",
                                    value: storyMedium.for_web,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("LabelledValue", {
                    attrs: { label: "Status", value: _vm.squeueTitle },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("LabelledValue", {
                    attrs: { label: "Pub date", value: _vm.pub_date },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("LabelledValue", {
                    attrs: {
                      label: "Print sections",
                      value: _vm.sectionTitles.join(", "),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("LabelledValue", {
                    attrs: { label: "Owner", value: _vm.userName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("LabelledValue", {
                    attrs: {
                      label: "Web categories",
                      value: _vm.webCategoryTitles.join(", "),
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.storyPlacements.length > 0
              ? [
                  _c("hr"),
                  _c("Label", { attrs: { text: "Issues" } }),
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.storyPlacements, function (placement) {
                      return _c("Placement", {
                        key: placement.id,
                        attrs: { placement: placement },
                      })
                    }),
                    1
                  ),
                ]
              : _vm._e(),
            _c("hr"),
            _c("Label", { attrs: { text: "File storage locations" } }),
            _vm._l(
              [_vm.content_path].concat(_vm.customFoldersStore.allPaths),
              function (path) {
                return _c("pre", { key: path }, [
                  _c("i", { staticClass: "fa fa-hdd-o" }),
                  _vm._v(" " + _vm._s(path)),
                ])
              }
            ),
            _c("hr"),
            _c("LabelledValue", {
              attrs: { label: "Notes", value: _vm.notes, "multi-line": true },
            }),
            _vm.currentUserStore.currentUser.show_advert_fields
              ? [
                  _c("hr"),
                  _c("LabelledCheckbox", {
                    attrs: { label: "Twitter feed", value: _vm.twitter_feed },
                  }),
                  _c("LabelledCheckbox", {
                    attrs: { label: "Tweet", value: _vm.tweet },
                  }),
                  _c("LabelledCheckbox", {
                    attrs: {
                      label: "Google Search Ad",
                      value: _vm.google_search_ad,
                    },
                  }),
                  _c("LabelledCheckbox", {
                    attrs: { label: "Outbrain Ad", value: _vm.outbrain_ad },
                  }),
                  _c("hr"),
                ]
              : _vm._e(),
            _c(
              "div",
              [
                _c("LabelledCheckbox", {
                  attrs: { label: "Freelance", value: _vm.freelance },
                }),
                _c("hr"),
              ],
              1
            ),
            _c("LabelledValue", {
              attrs: { label: "Export fields", value: _vm.exportFormatNames },
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", [
        _vm._v("Breakout box\n\n"),
        _c("i", { staticClass: "fa fa-angle-down" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", [
        _vm._v("Pull Quotes\n\n"),
        _c("i", { staticClass: "fa fa-angle-down" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", [
        _vm._v("Web\n\n"),
        _c("i", { staticClass: "fa fa-angle-down" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }