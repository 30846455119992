var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-toolbar" },
    [
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Undo",
                action: (chain) => chain.undo(),
                disabled: !_vm.editor || !_vm.editor.can().undo(),
              },
            },
            [_c("i", { staticClass: "fa fa-rotate-left" })]
          ),
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Redo",
                action: (chain) => chain.redo(),
                disabled: !_vm.editor || !_vm.editor.can().redo(),
              },
            },
            [_c("i", { staticClass: "fa fa-rotate-right" })]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Bold",
                activeCheck: "bold",
                action: (chain) => chain.toggleBold(),
              },
            },
            [_c("i", { staticClass: "fa fa-bold" })]
          ),
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Italic",
                activeCheck: "italic",
                action: (chain) => chain.toggleItalic(),
              },
            },
            [_c("i", { staticClass: "fa fa-italic" })]
          ),
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Underline",
                activeCheck: "underline",
                action: (chain) => chain.toggleUnderline(),
              },
            },
            [_c("i", { staticClass: "fa fa-underline" })]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "btn-group" }, [
        _vm._m(0),
        _c("ul", { staticClass: "dropdown-menu" }, [
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Quote",
                    activeCheck: "blockquote",
                    action: (chain) => chain.toggleBlockquote(),
                  },
                },
                [_vm._v("Quote")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Paragraph",
                    activeCheck: "paragraph",
                    action: (chain) => chain.setParagraph(),
                  },
                },
                [_vm._v("Paragraph")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Heading 1",
                    activeCheck: ["heading", { level: 1 }],
                    action: (chain) => chain.toggleHeading({ level: 1 }),
                  },
                },
                [_vm._v("Heading 1")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Heading 1",
                    activeCheck: ["heading", { level: 2 }],
                    action: (chain) => chain.toggleHeading({ level: 2 }),
                  },
                },
                [_vm._v("Heading 2")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Heading 1",
                    activeCheck: ["heading", { level: 3 }],
                    action: (chain) => chain.toggleHeading({ level: 3 }),
                  },
                },
                [_vm._v("Heading 3")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Heading 1",
                    activeCheck: ["heading", { level: 4 }],
                    action: (chain) => chain.toggleHeading({ level: 4 }),
                  },
                },
                [_vm._v("Heading 4")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Heading 1",
                    activeCheck: ["heading", { level: 5 }],
                    action: (chain) => chain.toggleHeading({ level: 5 }),
                  },
                },
                [_vm._v("Heading 5")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "ToolbarButton",
                {
                  attrs: {
                    "tag-type": "a",
                    classes: {},
                    editor: _vm.editor,
                    title: "Heading 1",
                    activeCheck: ["heading", { level: 6 }],
                    action: (chain) => chain.toggleHeading({ level: 6 }),
                  },
                },
                [_vm._v("Heading 6")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "ToolbarButton",
        {
          attrs: {
            editor: _vm.editor,
            title: "Clear formatting",
            action: (chain) => chain.unsetAllMarks(),
          },
        },
        [_c("i", { staticClass: "fa fa-eraser" })]
      ),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Bulleted list",
                activeCheck: "bulletList",
                action: (chain) => chain.toggleBulletList(),
              },
            },
            [_c("i", { staticClass: "fa fa-list-ul" })]
          ),
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Ordered list",
                activeCheck: "orderedList",
                action: (chain) => chain.toggleOrderedList(),
              },
            },
            [_c("i", { staticClass: "fa fa-list-ol" })]
          ),
          _vm._m(1),
          _c("div", { staticClass: "dropdown-menu" }, [
            _c("div", { staticClass: "justify-center" }, [
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _c(
                    "ToolbarButton",
                    {
                      attrs: {
                        editor: _vm.editor,
                        title: "Align left",
                        activeCheck: { textAlign: "left" },
                        action: (chain) => chain.setTextAlign("left"),
                      },
                    },
                    [_c("i", { staticClass: "fa fa-align-left" })]
                  ),
                  _c(
                    "ToolbarButton",
                    {
                      attrs: {
                        editor: _vm.editor,
                        title: "Align center",
                        activeCheck: { textAlign: "center" },
                        action: (chain) => chain.setTextAlign("center"),
                      },
                    },
                    [_c("i", { staticClass: "fa fa-align-center" })]
                  ),
                  _c(
                    "ToolbarButton",
                    {
                      attrs: {
                        editor: _vm.editor,
                        title: "Align right",
                        activeCheck: { textAlign: "right" },
                        action: (chain) => chain.setTextAlign("right"),
                      },
                    },
                    [_c("i", { staticClass: "fa fa-align-right" })]
                  ),
                  _c(
                    "ToolbarButton",
                    {
                      attrs: {
                        editor: _vm.editor,
                        title: "Align justify",
                        activeCheck: { textAlign: "justify" },
                        action: (chain) => chain.setTextAlign("justify"),
                      },
                    },
                    [_c("i", { staticClass: "fa fa-align-justify" })]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Create or edit hyperlink",
                activeCheck: "link",
                action: _vm.setLink,
              },
            },
            [_c("i", { staticClass: "fa fa-chain" })]
          ),
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Remove hyperlink",
                activeCheck: "link",
                action: (chain) => chain.unsetLink(),
              },
            },
            [_c("i", { staticClass: "fa fa-chain-broken" })]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "ToolbarButton",
            {
              attrs: {
                editor: _vm.editor,
                title: "Create a table",
                action: (chain) =>
                  chain.insertTable({ rows: 3, cols: 3, withHeaderRow: false }),
              },
            },
            [_c("i", { staticClass: "fa fa-table" })]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm dropdown-toggle",
              attrs: {
                type: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
                title: "Table options",
                disabled: !this.editor.isActive("table"),
              },
            },
            [
              _c("span", { staticClass: "caret" }),
              _c("span", { staticClass: "sr-only" }, [
                _vm._v("Toggle Dropdown"),
              ]),
            ]
          ),
          _c("ul", { staticClass: "dropdown-menu" }, [
            _c(
              "li",
              [
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      "tag-type": "a",
                      classes: {},
                      editor: _vm.editor,
                      title: "Toggle header row",
                      action: (chain) => chain.toggleHeaderRow(),
                    },
                  },
                  [_vm._v("Header row")]
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      "tag-type": "a",
                      classes: {},
                      editor: _vm.editor,
                      title: "Toggle header column",
                      action: (chain) => chain.toggleHeaderColumn(),
                    },
                  },
                  [_vm._v("Header col")]
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      "tag-type": "a",
                      classes: {},
                      editor: _vm.editor,
                      title: "Toggle header cell",
                      action: (chain) => chain.toggleHeaderCell(),
                    },
                  },
                  [_vm._v("Header cell")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
          title: "Style",
        },
      },
      [
        _c("i", { staticClass: "fa fa-magic" }),
        _c("span", { staticClass: "caret" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
          title: "Paragraph",
        },
      },
      [
        _c("i", { staticClass: "fa fa-align-left" }),
        _c("span", { staticClass: "caret" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }