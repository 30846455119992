var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CommunityQPublishModal"),
      _c("StoryEmailModal"),
      _c(
        "router-link",
        {
          staticClass: "btn btn-info pull-right",
          attrs: { to: `/issues/${_vm.page.issue.id}` },
        },
        [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v("\nBack")]
      ),
      _c("h1", [
        _vm._v(_vm._s(_vm.page.title) + "\n"),
        _c("small", [_vm._v(_vm._s(_vm.page.issue.title))]),
      ]),
      _c("hr"),
      _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-md-9" },
              [
                _c("h2", [_vm._v("Stories")]),
                _c(
                  "StoryFileDrop",
                  [
                    _vm.stories.length === 0
                      ? _c("div", { staticClass: "alert alert-info" }, [
                          _vm._v("No stories were found"),
                        ])
                      : _c("StoriesTable", {
                          attrs: { columns: _vm.COLUMNS },
                          on: {
                            sort: _vm.sortStories,
                            "should-refresh": function ($event) {
                              return _vm.fetchStories()
                            },
                          },
                        }),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [_c("NewStoryButton")],
                      1
                    ),
                  ],
                  1
                ),
                _c("hr"),
                _c(
                  "div",
                  { staticClass: "header" },
                  [
                    _c("h2", [_vm._v("Files")]),
                    _c("FileBreadcrumbs", {
                      attrs: { "resource-slug": _vm.page.slug },
                    }),
                    _c("div", { staticClass: "download-all text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-default",
                          on: { click: _vm.downloadZip },
                        },
                        [
                          _c("i", { staticClass: "fa fa-download" }),
                          _vm._v("\nDownload All"),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c("FileIndex", {
                  attrs: { resourceGlobalId: _vm.pageGlobalId },
                }),
              ],
              1
            ),
            _c("Sidebar", { staticClass: "col-md-3 page" }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }