var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header", [
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-default",
                attrs: { to: "/admin/transforms/new" },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v("\nNew Document Transformation"),
              ]
            ),
          ],
          1
        ),
        _c("h1", [_vm._v("Document Transformations")]),
      ]),
      _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
        _c("table", { staticClass: "table table-striped" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Name")]),
              _c("th", [_vm._v("Rules")]),
              _c("th", { staticClass: "actions" }),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.transforms, function (transform) {
                return [
                  _c("Row", {
                    key: transform.id,
                    attrs: { transform: transform },
                  }),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }