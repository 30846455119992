var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loader-overlay" },
    [
      _vm.loading
        ? _c(
            "div",
            { class: _vm.cssClasses },
            [_c("Loader", { staticClass: "loader", attrs: { size: "5x" } })],
            1
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }