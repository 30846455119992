var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "locked-overlay" },
    [
      _c("div", { class: _vm.cssClasses }, [
        _vm.wasUpdated
          ? _c("div", { staticClass: "panel panel-warning" }, [
              _vm._m(0),
              _c("div", { staticClass: "panel-body" }, [
                _vm._m(1),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.viewChanges },
                    },
                    [
                      _c("i", { staticClass: "fa fa-eye" }),
                      _vm._v("\nView Changes"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.refresh },
                    },
                    [
                      _c("i", { staticClass: "fa fa-refresh" }),
                      _vm._v("\nRefresh"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.editAnyway },
                    },
                    [
                      _c("i", { staticClass: "fa fa-pencil" }),
                      _vm._v("\nEdit Anyway"),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.lockedToUser
          ? _c("div", { staticClass: "panel panel-danger" }, [
              _vm._m(2),
              _c("div", { staticClass: "panel-body" }, [
                _c("p", [
                  _vm._v(
                    "This story was locked by " +
                      _vm._s(_vm.locker.name) +
                      ". They may have unsaved changes not reflected here.\nIf you choose to unlock it, they will immediately be locked out, and may lose their unsaved changes."
                  ),
                ]),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn-lg",
                      attrs: { type: "button" },
                      on: { click: _vm.forceLock },
                    },
                    [
                      _c("i", { staticClass: "fa fa-unlock" }),
                      _vm._v("\nUnlock"),
                    ]
                  ),
                ]),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-prominent btn-lg",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goToShow()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-eye" }),
                      _vm._v("\nRead Only"),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", [
        _c("i", { staticClass: "fa fa-lock" }),
        _vm._v("\nThis story has been updated"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("This story has changed while you were away."),
      _c("br"),
      _vm._v("If you choose to edit it anyway, the\n"),
      _c("strong", [_vm._v("recent changes may be lost.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", [
        _c("i", { staticClass: "fa fa-lock" }),
        _vm._v("\nThis story is locked"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }