var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel panel-default pull-quotes" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-md-6 text-right" },
          [
            _c("ShowHideButton", {
              staticClass: "btn-sm",
              model: {
                value: _vm.showPullQuotes,
                callback: function ($$v) {
                  _vm.showPullQuotes = $$v
                },
                expression: "showPullQuotes",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPullQuotes,
            expression: "showPullQuotes",
          },
        ],
        staticClass: "panel-body",
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("Headline")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.headline,
                expression: "headline",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.headline },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.headline = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("Quote")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quote,
                expression: "quote",
              },
            ],
            staticClass: "form-control",
            domProps: { value: _vm.quote },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.quote = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Attribution"),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.attribution,
                expression: "attribution",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.attribution },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.attribution = $event.target.value
              },
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h4", { staticStyle: { "margin-top": "7px" } }, [
        _vm._v("Pull Quotes"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }