var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("header", [
      _c(
        "div",
        { staticClass: "pull-right" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-default",
              attrs: { to: "/admin/squeues/new" },
            },
            [_vm._v("New Section Status")]
          ),
        ],
        1
      ),
      _c("h1", [_vm._v("Section Statuses")]),
    ]),
    _c(
      "table",
      { staticClass: "table table-striped" },
      [
        _vm._m(0),
        _c(
          "draggable",
          {
            attrs: { tag: "tbody", handle: ".handle" },
            on: { change: _vm.save },
            model: {
              value: _vm.taxonomies,
              callback: function ($$v) {
                _vm.taxonomies = $$v
              },
              expression: "taxonomies",
            },
          },
          [
            _vm._l(_vm.taxonomies, function (squeue) {
              return [
                _c("tr", { key: squeue.id }, [
                  _c("td", {
                    staticClass: "color",
                    style: { "background-color": squeue.color },
                  }),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: `/admin/squeues/${squeue.id}/edit` } },
                        [_vm._v(_vm._s(squeue.title))]
                      ),
                    ],
                    1
                  ),
                  _c("td", [
                    _c("code", {
                      domProps: { textContent: _vm._s(squeue.slug) },
                    }),
                  ]),
                  _c("td", [
                    _c("i", {
                      staticClass: "fa",
                      class: [_vm.triggerWebExportIcon(squeue)],
                      attrs: { title: _vm.triggerWebExportTitle(squeue) },
                    }),
                    _vm._v("\n" + _vm._s(_vm.triggerWebExportText(squeue))),
                  ]),
                  _c("td", [
                    _c("i", { class: _vm.checkboxClass(squeue.system_record) }),
                  ]),
                  _c("td", [
                    _c("i", { class: _vm.checkboxClass(squeue.enabled) }),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "handle btn btn-default btn-sm",
                        staticStyle: { cursor: "move" },
                      },
                      [_c("i", { staticClass: "fa fa-sort" })]
                    ),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "color" }),
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Code")]),
        _c("th", [_vm._v("Auto web export")]),
        _c("th", [_vm._v("System?")]),
        _c("th", [_vm._v("Enabled?")]),
        _c("th", { staticClass: "text-right" }, [_vm._v("Sort")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }