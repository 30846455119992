var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-labelled-editable-value" },
    [
      _c("Label", { attrs: { tag: "div", text: _vm.label } }),
      _vm.editing && _vm.multiLine
        ? [
            _c("TextareaInput", {
              ref: "textarea",
              on: { blur: _vm.blur },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm._e(),
      _vm.editing && !_vm.multiLine
        ? [
            _c("TextInput", {
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
          ]
        : _vm._e(),
      !_vm.editing
        ? [
            _c("Value", {
              attrs: { value: _vm.value, "action-icon": "pencil" },
              on: { "action-clicked": _vm.edit },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }