<template lang="pug">
.locations
  .row
    .col-md-12
      header
        .pull-right
          router-link.btn.btn-primary(to="/locations/new")
            i.fa.fa-plus
            |
            | New Location
        h1 Locations

  .row
    .col-md-12
      section.well.well-sm.top-section
        form.inline-form(v-on:submit.prevent="fetchSearchResults")
          // TODO: the search bar is too wide here. It should be redesigned a bit or if we add other filters, maybe it'll be fine
          .input-group
            input.form-control(type="search" placeholder="Search" v-model="search" title="Search")
            span.input-group-btn
              button.btn.btn-prominent(type="button" v-on:click="")
                | Search

  Table(
    v-if="locations.length > 0"
    :locations="locations"
  )
  template(v-if="locations.length < 1")
    .alert.alert-info No locations were found
</template>

<script>
  import { mapStores } from 'pinia'
  import { useLocationsStore } from '../../../stores/locations'

  import Table from './table.vue'

  export default {
    components: {
      Table
    },

    data() {
      return {
        search: '',
        page: 1,
        per_page: 25
      }
    },

    computed: {
      ...mapStores(useLocationsStore),

      locations() {
        return Object.values(this.locationsStore.searchResults)
      }
    },

    async mounted() {
      await this.fetchSearchResults()
    },

    methods: {
      async fetchSearchResults() {
        const { search, per_page, page } = this

        // TODO: add a loader and show/hide it here

        await this.locationsStore.search({ search, page, per_page })
      }
    }
  }
</script>
