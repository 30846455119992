var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-2" }),
    _c("div", { staticClass: "col-md-8" }, [
      _c("h1", [_vm._v("New Transform")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Name" } }),
                  _c("Input", {
                    model: {
                      value: _vm.transform.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.transform, "name", $$v)
                      },
                      expression: "transform.name",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-7" },
              [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v("Save")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-link text-muted",
                    attrs: { to: "/admin/transforms" },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "col-md-2" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }