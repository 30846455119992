var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "admin-style-maps-edit" } }, [
    _c(
      "header",
      [
        _c(
          "router-link",
          {
            staticClass: "btn btn-info pull-right",
            attrs: { to: "/admin/style_maps" },
          },
          [_c("i", { staticClass: "fa fa-arrow-left" }), _vm._v("\nBack")]
        ),
        _c("h1", [
          _vm._v(_vm._s(_vm.styleMap && _vm.styleMap.name) + " Style Map"),
        ]),
      ],
      1
    ),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save()
          },
        },
      },
      [
        _c("table", { staticClass: "table table-striped" }, [
          _c("col", { staticStyle: { width: "45%" } }),
          _c("col", { staticStyle: { width: "10%" } }),
          _c("col", { staticStyle: { width: "45%" } }),
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.styleMappings, function (mapping) {
              return _c("tr", { key: mapping.style }, [
                _c("td", [
                  _c("i", {
                    staticClass: "fa",
                    class: _vm.STYLE_ICONS[mapping.style],
                  }),
                  _vm._v("\n\n" + _vm._s(mapping.style_name)),
                ]),
                _vm._m(1, true),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: mapping.custom_name,
                        expression: "mapping.custom_name",
                      },
                    ],
                    staticClass: "form-control",
                    domProps: { value: mapping.custom_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(mapping, "custom_name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ])
            }),
            0
          ),
        ]),
        _vm._m(2),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Style")]),
        _c("th"),
        _c("th", [_vm._v("ICML Character Style")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("i", { staticClass: "fa fa-exchange" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "text-right" }, [
      _c(
        "button",
        { staticClass: "btn btn-success", attrs: { type: "submit" } },
        [_c("i", { staticClass: "fa fa-save" }), _vm._v("\nSave")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }