var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _vm.editing
        ? [
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.name,
                    expression: "form.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _c("td", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.default,
                      expression: "form.default",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.form.default)
                      ? _vm._i(_vm.form.default, null) > -1
                      : _vm.form.default,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.default,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "default", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "default",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "default", $$c)
                      }
                    },
                  },
                }),
              ]),
            ]),
            _c("td", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-xs",
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-save" }), _vm._v("\nSave")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default btn-xs",
                  on: {
                    click: function ($event) {
                      _vm.editing = false
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-ban" }), _vm._v("\nCancel")]
              ),
            ]),
          ]
        : [
            _c(
              "td",
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: `/admin/style_maps/${_vm.styleMap.id}/edit` },
                  },
                  [_vm._v(_vm._s(_vm.styleMap.name))]
                ),
              ],
              1
            ),
            _c(
              "td",
              [
                _c("CheckboxIcon", {
                  attrs: { checked: _vm.styleMap.default },
                }),
              ],
              1
            ),
            _c("td", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default btn-xs",
                  on: {
                    click: function ($event) {
                      _vm.editing = true
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-pencil" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger btn-xs",
                  on: {
                    click: function ($event) {
                      return _vm.destroy()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-trash" })]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }