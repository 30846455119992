import { defineStore } from 'pinia'
import { compact } from 'lodash'
import { snakeKeyed } from '../utils/params_helpers'
import { timeToEndOfDay } from '../utils/time_helpers'

export const useMediaSearchStore = defineStore('mediaSearch', {
  state: () => ({
    search: '',
    filterImages: false,
    filterDocuments: false,
    squeueIds: [],
    sectionIds: [],
    from: null,
    to: null
  }),

  getters: {
    forQueryParams: ({ search, squeueIds, sectionIds, from, to, filterImages, filterDocuments }) => {
      const types = compact([
        (filterImages ? 'images' : null),
        (filterDocuments ? 'documents' : null),
      ])

      return snakeKeyed({
        search,
        squeueIds,
        sectionIds,
        from,
        to: timeToEndOfDay(to),
        types
      })
    }
  }
})
