var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-2" }),
    _c(
      "div",
      { staticClass: "col-md-8" },
      [
        _c("h1", [_vm._v("Edit " + _vm._s(_vm.squeue.name))]),
        _c(
          "LoaderOverlay",
          { attrs: { loading: _vm.loading } },
          [_c("Form", { attrs: { squeue: _vm.squeue } })],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "col-md-2" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }