var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "story-publications" },
    _vm._l(_vm.publications, function (publication) {
      return !_vm.storyPubsLoading
        ? _c("StoryPublication", {
            key: publication.id,
            attrs: { "story-id": _vm.storyId, publication: publication },
          })
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }