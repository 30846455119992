<template lang="pug">
.form-group
  label.control-label Status
  select.form-control(v-model="onlinestatusId")
    option(value="") Select an Online Status
    option(v-for="onlineStatus in onlinestatusesStore.onlinestatuses" :value="onlineStatus.id") {{ onlineStatus.title }}
</template>

<script>
  import { mapStores } from 'pinia'
  import { useOnlinestatusesStore } from '../../../stores/onlinestatuses'

  export default {
    props: {
      // NOTE: be careful that the value matches the option value. Number strings are a common issue.
      value: {
        type: Number,
        default() { return null }
      }
    },

    data() {
      return {
        onlinestatusId: null
      }
    },

    computed: {
      ...mapStores(useOnlinestatusesStore)
    },

    watch: {
      value() {
        this.onlinestatusId = this.value
      },

      onlinestatusId() {
        // For component v-model support
        this.$emit('input', this.onlinestatusId);
      }
    },

    async mounted() {
      await this.onlinestatusesStore.fetchOnce()
    }
  }
</script>
