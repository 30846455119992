var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "btn btn-info btn-file", on: { change: _vm.changeHandler } },
    [
      _c("i", { staticClass: "fa fa-upload" }),
      _c("input", { attrs: { type: "file", multiple: "" } }),
      _vm._v("\nUpload"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }