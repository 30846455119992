var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "story-publication",
      class: _vm.otherPublications ? "panel panel-default" : "",
    },
    [
      _vm.otherPublications
        ? [
            _c("div", { staticClass: "panel-heading" }, [
              _c("h4", [
                _vm._v(_vm._s(_vm.name) + "\n\n"),
                _c("i", { staticClass: "fa fa-angle-down" }),
              ]),
            ]),
          ]
        : _vm._e(),
      _c(
        "div",
        { class: _vm.otherPublications ? "panel-body" : "" },
        [
          _c("LabelledValue", {
            attrs: { label: "Exported at", value: _vm.exportedAt },
          }),
          _c("LabelledValue", {
            attrs: {
              label: "Content type",
              value: _vm.storyPublication.content_type_key,
            },
          }),
          _c("LabelledValue", {
            attrs: { label: "Story template", value: _vm.storyTemplate },
          }),
          _c("LabelledValue", {
            attrs: { label: "Subsites", value: _vm.subsiteNames },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }