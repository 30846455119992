var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table table-striped table-hover content-list" },
    [
      _c("thead", [
        _c("tr", [
          _vm.showCol("color") ? _c("th") : _vm._e(),
          _vm.showCol("checkbox") &&
          !_vm.currentUserStore.currentUser.role_is_limited
            ? _c(
                "th",
                [
                  _c("CheckBox", {
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("id")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "id", text: "ID" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("pub_date")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "pub_date", text: "Pub date" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("slug")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "slug", text: "Slug" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("name")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "headline", text: "Headline" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("sections")
            ? _c("th", [_vm._v("Print sections")])
            : _vm._e(),
          _vm.showCol("web_categories")
            ? _c("th", [_vm._v("Web sections")])
            : _vm._e(),
          _vm.showCol("words")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "words", text: "Words / Inches" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("media")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "media_count", text: "Attachments" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("exported")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "exported", text: "Web Export" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("squeue")
            ? _c(
                "th",
                [
                  _c("SortLink", {
                    attrs: { column: "squeue_title", text: "Status" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showCol("actions")
            ? _c("th", { staticClass: "actions" })
            : _vm._e(),
        ]),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.stories, function (story) {
          return _c(
            "StoryRow",
            _vm._g(
              { key: story.id, attrs: { story: story, columns: _vm.columns } },
              _vm.$listeners
            )
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }