var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "btn btn-default",
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.toggle()
        },
      },
    },
    [
      _vm._v(_vm._s(_vm.label) + "\n"),
      _c("i", { staticClass: "fa", class: _vm.iconClass }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }