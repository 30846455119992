var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-folder input-group" },
    [
      _vm._m(0),
      _c("Input", {
        on: {
          blur: function ($event) {
            return _vm.updateCustomFolder()
          },
        },
        model: {
          value: _vm.customFolder.path,
          callback: function ($$v) {
            _vm.$set(_vm.customFolder, "path", $$v)
          },
          expression: "customFolder.path",
        },
      }),
      _c("span", { staticClass: "input-group-btn" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-danger",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.deleteCustomFolder()
              },
            },
          },
          [_c("i", { staticClass: "fa fa-times" })]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-hdd-o" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }