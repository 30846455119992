var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentUserStore.currentUser.can_change_ownership
    ? _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "control-label" }, [_vm._v("Owner")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userId,
                expression: "userId",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.userId = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.$store.getters["users/all"], function (user) {
            return _c("option", { domProps: { value: user.id } }, [
              _vm._v(_vm._s(user.name)),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }