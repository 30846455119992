var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "communityq-story-template-field" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedId,
            expression: "selectedId",
          },
        ],
        staticClass: "form-control",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.selectedId = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        _c("option"),
        _vm._l(
          _vm.communityqStoryTemplatesStore.storyTemplates,
          function (storyTemplate) {
            return _c(
              "option",
              { key: storyTemplate.id, domProps: { value: storyTemplate.id } },
              [_vm._v(_vm._s(storyTemplate.title))]
            )
          }
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }