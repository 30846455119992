var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentUserStore.currentUser.can_set_squeue
    ? _c("div", { staticClass: "form-group" }, [
        _vm.showLabel
          ? _c("label", { staticClass: "control-label" }, [
              _vm._v("Status"),
              _vm.required
                ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "select",
          {
            staticClass: "form-control",
            attrs: { disabled: _vm.disabled },
            domProps: { value: _vm.value },
            on: { input: _vm.updateSqueueId },
          },
          [
            !_vm.required
              ? _c("option", { domProps: { value: null } })
              : _vm._e(),
            _vm._l(_vm.squeues, function (squeue) {
              return _c("option", { domProps: { value: squeue.id } }, [
                _vm._v(_vm._s(squeue.title)),
              ])
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }