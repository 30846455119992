var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table table-striped" }, [
    _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-center" }, [_vm._v("Favorite")]),
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Query")]),
        _c("th", [_vm._v("Default")]),
        _vm.hotQueues ? _c("th", [_vm._v("Hot queue")]) : _vm._e(),
        _c("th", { staticClass: "actions" }),
      ]),
    ]),
    _c(
      "tbody",
      _vm._l(
        _vm.storySavedSearchesStore.storySavedSearches,
        function (storySavedSearch) {
          return _c("Row", {
            key: storySavedSearch.id,
            attrs: { "story-saved-search": storySavedSearch },
          })
        }
      ),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }