var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "LoaderOverlay",
    { attrs: { loading: _vm.loading } },
    [_c("IssueForm")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }