var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Location")]),
      _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
        _c("form", [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("Label", { attrs: { text: "Title" } }),
              _c("Input", {
                model: {
                  value: _vm.location.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.location, "title", $$v)
                  },
                  expression: "location.title",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Address 1" } }),
                  _c("Input", {
                    model: {
                      value: _vm.location.address_street1,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "address_street1", $$v)
                      },
                      expression: "location.address_street1",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "City" } }),
                  _c("Input", {
                    model: {
                      value: _vm.location.address_street1,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "address_street1", $$v)
                      },
                      expression: "location.address_street1",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "State" } }),
                  _c("Input", {
                    model: {
                      value: _vm.location.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "state", $$v)
                      },
                      expression: "location.state",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Zip" } }),
                  _c("Input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.location.zip,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "zip", $$v)
                      },
                      expression: "location.zip",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Address 2" } }),
                  _c("Input", {
                    model: {
                      value: _vm.location.address_street2,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "address_street2", $$v)
                      },
                      expression: "location.address_street2",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Phone 1" } }),
                  _c("Input", {
                    attrs: { type: "tel" },
                    model: {
                      value: _vm.location.phone1,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "phone1", $$v)
                      },
                      expression: "location.phone1",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Phone 2" } }),
                  _c("Input", {
                    attrs: { type: "tel" },
                    model: {
                      value: _vm.location.phone2,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "phone2", $$v)
                      },
                      expression: "location.phone2",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Email" } }),
                  _c("Input", {
                    attrs: { type: "email" },
                    model: {
                      value: _vm.location.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "email", $$v)
                      },
                      expression: "location.email",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Fax" } }),
                  _c("Input", {
                    attrs: { type: "tel" },
                    model: {
                      value: _vm.location.fax,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "fax", $$v)
                      },
                      expression: "location.fax",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Website" } }),
                  _c("Input", {
                    model: {
                      value: _vm.location.website,
                      callback: function ($$v) {
                        _vm.$set(_vm.location, "website", $$v)
                      },
                      expression: "location.website",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("Label", { attrs: { text: "Description" } }),
              _c("TextareaInput", {
                attrs: { type: "" },
                model: {
                  value: _vm.location.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.location, "description", $$v)
                  },
                  expression: "location.description",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "row text-nowrap" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-link text-muted",
                    attrs: { to: "/locations/" },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }