var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-sm-6 col-md-4 col-sg-3" }, [
    _c(
      "div",
      { staticClass: "thumbnail", class: { selected: _vm.selected } },
      [
        _vm.isImage
          ? [
              _c("ImageLoader", {
                attrs: { imgClass: "thumb", src: _vm.thumbUrl },
              }),
            ]
          : [
              _c("div", { staticClass: "thumb file text-center text-muted" }, [
                _c("i", { staticClass: "fa fa-file-o" }),
                _c("div", { staticClass: "extension" }, [
                  _vm._v("." + _vm._s(_vm.fileExtension)),
                ]),
              ]),
            ],
        _c("div", { staticClass: "caption" }, [
          _vm.stories.length > 0
            ? _c("span", { staticClass: "stories text-muted" }, [
                _c("i", { staticClass: "fa fa-newspaper-o" }),
              ])
            : _vm._e(),
          _c("span", { staticClass: "filename text-muted" }, [
            _vm._v(_vm._s(_vm.mediaFileName)),
          ]),
        ]),
        _vm.selected
          ? _c("i", { staticClass: "check fa fa-check-square" })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }