var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.storyBatchOptionsStore.anySelectedStories
      ? _c("section", { staticClass: "batch-options panel panel-default" }, [
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Pub date")]),
                    _c("DateField", {
                      attrs: {
                        "input-class": "form-control",
                        "disabled-dates": { to: new Date() },
                        placeholder: "Don't Change",
                      },
                      model: {
                        value: _vm.storyBatchOptionsStore.pubDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.storyBatchOptionsStore, "pubDate", $$v)
                        },
                        expression: "storyBatchOptionsStore.pubDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Owner")]),
                    _c("SelectInput", {
                      attrs: {
                        "blank-option-name": "Don't Change",
                        options: _vm.userOptions,
                      },
                      model: {
                        value: _vm.storyBatchOptionsStore.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.storyBatchOptionsStore, "userId", $$v)
                        },
                        expression: "storyBatchOptionsStore.userId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Print section")]),
                    _c("SelectInput", {
                      attrs: {
                        "blank-option-name": "Don't Change",
                        options: _vm.sectionOptions,
                      },
                      model: {
                        value: _vm.storyBatchOptionsStore.sectionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.storyBatchOptionsStore, "sectionId", $$v)
                        },
                        expression: "storyBatchOptionsStore.sectionId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Status")]),
                    _c("SelectInput", {
                      attrs: {
                        "blank-option-name": "Don't Change",
                        options: _vm.squeueOptions,
                      },
                      model: {
                        value: _vm.storyBatchOptionsStore.squeueId,
                        callback: function ($$v) {
                          _vm.$set(_vm.storyBatchOptionsStore, "squeueId", $$v)
                        },
                        expression: "storyBatchOptionsStore.squeueId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Web category")]),
                    _c("SelectInput", {
                      attrs: {
                        "blank-option-name": "Don't Change",
                        options: _vm.webCategoriesStore.allAsObject,
                      },
                      model: {
                        value: _vm.storyBatchOptionsStore.webCategoryId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.storyBatchOptionsStore,
                            "webCategoryId",
                            $$v
                          )
                        },
                        expression: "storyBatchOptionsStore.webCategoryId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12 text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-default",
                    attrs: { href: _vm.downloadLink },
                  },
                  [
                    _c("i", { staticClass: "fa fa-cloud-download" }),
                    _vm._v("\nDownload"),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function ($event) {
                        return _vm.openCommunityQPublishModal()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-cloud-upload" }),
                    _vm._v("\nExport to Web"),
                  ]
                ),
                _vm._v(" "),
                _c("button", {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "button",
                    disabled: !_vm.storyBatchOptionsStore.anyChanges,
                  },
                  domProps: { textContent: _vm._s(_vm.buttonTitle("Update")) },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }