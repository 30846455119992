var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vue-labelled-checkbox-root", on: { click: _vm.toggle } },
    [
      _c("CheckboxIcon", {
        staticClass: "checkbox",
        attrs: { checked: _vm.value },
      }),
      _vm._v(" "),
      _c("Label", {
        attrs: { tag: "div", text: _vm.label },
        on: { click: _vm.toggle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }