import { render, staticRenderFns } from "./file_content_linked_field.vue?vue&type=template&id=b1864fbe&lang=pug"
import script from "./file_content_linked_field.vue?vue&type=script&lang=js"
export * from "./file_content_linked_field.vue?vue&type=script&lang=js"
import style0 from "./file_content_linked_field.vue?vue&type=style&index=0&id=b1864fbe&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/newsroomq/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b1864fbe')) {
      api.createRecord('b1864fbe', component.options)
    } else {
      api.reload('b1864fbe', component.options)
    }
    module.hot.accept("./file_content_linked_field.vue?vue&type=template&id=b1864fbe&lang=pug", function () {
      api.rerender('b1864fbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/components/stories/form/file_content_linked_field.vue"
export default component.exports