var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "story-select" }, [
    _c("div", { staticClass: "panel panel-default" }, [
      _c("div", { staticClass: "panel-heading" }, [
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "search", placeholder: "Search" },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
          _vm._m(0),
        ]),
      ]),
      _c(
        "ul",
        { staticClass: "story-list list-group" },
        _vm._l(_vm.stories, function (story) {
          return _c(
            "li",
            {
              key: story.id,
              staticClass: "list-group-item",
              class: { active: story == _vm.selected },
              on: {
                click: function ($event) {
                  return _vm.select(story)
                },
              },
            },
            [
              _c("strong", [_vm._v(_vm._s(story.name))]),
              _c("div", { domProps: { innerHTML: _vm._s(story.byline) } }),
            ]
          )
        }),
        0
      ),
      _vm.$slots.footer
        ? _c("div", { staticClass: "panel-footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }