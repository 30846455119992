var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.anySavedSearches
    ? _c("div", { staticClass: "btn-group saved-searches" }, [
        _vm._m(0),
        _vm._m(1),
        _c(
          "ul",
          { staticClass: "dropdown-menu" },
          [
            _vm.storySavedSearchesStore.storySavedSearches.length > 0
              ? _c("li", { staticClass: "dropdown-header" }, [
                  _vm._v("Saved Searches"),
                ])
              : _vm._e(),
            _vm._l(
              _vm.storySavedSearchesStore.storySavedSearches,
              function (search) {
                return _c("li", [
                  _c("a", { attrs: { href: search.query } }, [
                    _vm._v(_vm._s(search.title)),
                  ]),
                ])
              }
            ),
            _vm.storySavedSearchesStore.storySavedSearches.length > 0
              ? _c("li", { staticClass: "text-right" }, [
                  _c("a", { attrs: { href: _vm.managePath } }, [_vm._m(2)]),
                ])
              : _vm._e(),
            _vm.anyQueryConditions
              ? [
                  _c("li", { staticClass: "divider" }),
                  _c("li", [
                    _c("div", { staticClass: "navbar-form" }, [
                      _c("div", { staticClass: "input-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newSearchName,
                              expression: "newSearchName",
                            },
                          ],
                          staticClass: "form-control input-sm",
                          attrs: { placeholder: "Save this search as" },
                          domProps: { value: _vm.newSearchName },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.saveNewSearch()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.newSearchName = $event.target.value
                            },
                          },
                        }),
                        _c("span", { staticClass: "input-group-btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default btn-sm",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.saveNewSearch()
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-plus" })]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _c(
        "button",
        { staticClass: "btn btn-prominent btn-sm", attrs: { type: "submit" } },
        [_c("i", { staticClass: "fa fa-search" }), _vm._v("\nSearch")]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn btn-prominent btn-sm", attrs: { type: "submit" } },
      [_c("i", { staticClass: "fa fa-search" }), _vm._v("\nSearch")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-prominent btn-sm dropdown-toggle",
        attrs: { type: "button", "data-toggle": "dropdown" },
      },
      [_c("i", { staticClass: "fa fa-caret-down" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("i", { staticClass: "fa fa-cog text-muted" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [_vm._v("manage")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }