var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-folders" },
    [
      _vm._l(_vm.customFoldersStore.customFolders, function (folder) {
        return _c("CustomFolder", {
          key: folder.id,
          attrs: { "custom-folder-id": folder.id },
        })
      }),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-link",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.createNew()
              },
            },
          },
          [
            _c("i", { staticClass: "fa fa-plus" }),
            _vm._v("\nAdd file storage location"),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }