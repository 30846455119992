var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-sm-6 col-md-4 col-sg-3" }, [
    _c(
      "div",
      { staticClass: "thumbnail" },
      [
        !_vm.isDirectory
          ? [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-link btn-lg show-on-hover download-file",
                  on: {
                    click: function ($event) {
                      return _vm.downloadFile()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-arrow-down" })]
              ),
            ]
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "btn btn-link btn-lg show-on-hover delete-file",
            on: {
              click: function ($event) {
                return _vm.deleteFile()
              },
            },
          },
          [_c("i", { staticClass: "fa fa-trash" })]
        ),
        _vm.isDirectory
          ? [
              _c("div", { staticClass: "thumb file text-center text-muted" }, [
                _c("a", { on: { click: _vm.goToDirectory } }, [
                  _c("i", { staticClass: "fa fa-folder-o" }),
                ]),
              ]),
            ]
          : _vm._e(),
        _vm.isImage
          ? [_c("img", { staticClass: "thumb", attrs: { src: _vm.thumbUrl } })]
          : _vm._e(),
        !_vm.isDirectory && !_vm.isImage
          ? [
              _c("div", { staticClass: "thumb file text-center text-muted" }, [
                _c("i", { staticClass: "fa fa-file-o" }),
                _c("div", { staticClass: "extension" }, [
                  _vm._v(_vm._s(_vm.extension)),
                ]),
              ]),
            ]
          : _vm._e(),
        _c("div", { staticClass: "caption" }, [
          _c("span", { staticClass: "filename text-muted" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }