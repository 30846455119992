var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flie-drop-overlay" },
    [
      _vm.fileHover
        ? _c(
            "div",
            {
              class: _vm.cssClasses,
              on: {
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.overlayDropHandler.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "wrapper text-center" },
                [
                  _c("h1", { staticClass: "indicator" }, [
                    _c(
                      "span",
                      { staticClass: "fa-stack fa-lg" },
                      [
                        _c("i", { staticClass: "fa fa-cloud fa-stack-2x" }),
                        !_vm.uploading
                          ? _c("i", {
                              staticClass:
                                "fa fa-arrow-up fa-stack-1x fa-inverse",
                            })
                          : _vm._e(),
                        _vm.uploading
                          ? _c("Loader", { staticClass: "fa-inverse" })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("br"),
                    _vm._v("Drop Files Here"),
                  ]),
                  _vm._t("description"),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }