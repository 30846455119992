var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "list-group placement-dropdown",
      class: { opened: _vm.opened },
    },
    [
      _c("Placement", { attrs: { placement: _vm.placement } }, [
        _c(
          "a",
          {
            staticClass: "open",
            on: {
              click: function ($event) {
                _vm.openedValue = !_vm.openedValue
              },
            },
          },
          [
            _c("i", {
              staticClass: "fa",
              class: {
                "fa-angle-down": !_vm.opened,
                "fa-angle-up": _vm.opened,
              },
            }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "placement-dropdown-dropdown" },
        [
          _vm._l(_vm.publications, function (pub) {
            return _vm.opened && _vm.publicationIssues(pub).length > 0
              ? [
                  _c("li", { staticClass: "list-group-item disabled" }, [
                    _c("strong", [_vm._v(_vm._s(pub.name))]),
                  ]),
                  _vm._l(_vm.publicationIssues(pub), function (issue) {
                    return [
                      _c("IssueRow", {
                        attrs: { issue: issue },
                        model: {
                          value: _vm.inputValue,
                          callback: function ($$v) {
                            _vm.inputValue = $$v
                          },
                          expression: "inputValue",
                        },
                      }),
                      _vm._l(_vm.issuePages(issue), function (page) {
                        return _vm.issueId === issue.id
                          ? _c("PageRow", {
                              key: issue.id,
                              attrs: { page: page },
                              model: {
                                value: _vm.inputValue,
                                callback: function ($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue",
                              },
                            })
                          : _vm._e()
                      }),
                    ]
                  }),
                ]
              : _vm._e()
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }