var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.singleFileMode
        ? [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                staticClass: "form-control",
                class: _vm.inputClass || "",
                attrs: { type: "text", readonly: _vm.readOnlyOrSingleFileMode },
                domProps: { value: _vm.inputValue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputValue = $event.target.value
                  },
                },
              }),
              _c(
                "span",
                {
                  ref: "helpButton",
                  staticClass: "input-group-addon",
                  attrs: {
                    "data-toggle": "tooltip",
                    "data-placement": "top",
                    title:
                      "Edit the content file linked to this story to change this field",
                  },
                },
                [_c("i", { staticClass: "fa fa-question" })]
              ),
            ]),
          ]
        : [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              staticClass: "form-control",
              class: _vm.inputClass || "",
              attrs: { type: "text", readonly: _vm.readOnlyOrSingleFileMode },
              domProps: { value: _vm.inputValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.inputValue = $event.target.value
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }