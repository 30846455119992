var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-default",
      attrs: { rel: "nofollow", disabled: _vm.loading },
      on: { click: _vm.click },
    },
    [
      _vm.loading ? [_c("Loader"), _c("br")] : _vm._e(),
      !_vm.loading
        ? [_c("i", { staticClass: "fa fa-trash text-muted" })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }