import { defineStore } from 'pinia'
import axios from 'axios'
import store from '../store'

export const useOnlinestatusesStore = defineStore('onlinestatuses', {
  state: () => ({
    onlinestatuses: [],
    fetchInitiated: false
  }),

  getters: {
    forId: (state) => (id) => state.onlinestatuses.find(onlinestatus => onlinestatus.id === id)
  },

  actions: {
    async fetch() {
      this.fetchInitiated = true

      return axios.get('/api/internal/onlinestatuses')
        .then(({ data: { onlinestatuses }}) => {
          this.onlinestatuses = onlinestatuses
        })
        .catch((err) => {
          store.dispatch('messages/smartAdd', err)
        })
    },

    async fetchOnce() {
      if (this.fetchInitiated) return Promise.resolve()

      return this.fetch()
    },
  }
})
