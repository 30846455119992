var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "control-label" }, [_vm._v("Print Sections")]),
    _c(
      "div",
      [
        _c("DropdownCheckboxes", {
          staticClass: "block",
          attrs: {
            label: "Print Sections",
            options: _vm.$store.getters["sections/enabledAsObject"],
          },
          model: {
            value: _vm.sectionIds,
            callback: function ($$v) {
              _vm.sectionIds = $$v
            },
            expression: "sectionIds",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }