var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "input-group" },
    [
      _vm.isCharValue
        ? [
            _c("SelectInput", {
              attrs: { options: _vm.characterOptions },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
            _c("span", { staticClass: "input-group-btn" }, [
              _c(
                "button",
                {
                  ref: "textModeButton",
                  staticClass: "btn btn-default",
                  attrs: {
                    type: "button",
                    "data-toggle": "tooltip",
                    "data-placement": "right",
                    title: "Switch to text input",
                  },
                  on: { click: _vm.switchToText },
                },
                [_c("i", { staticClass: "fa fa-align-left" })]
              ),
            ]),
          ]
        : _vm._e(),
      !_vm.isCharValue
        ? [
            _c("TextInput", {
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            }),
            _c("span", { staticClass: "input-group-btn" }, [
              _c(
                "button",
                {
                  ref: "charModeButton",
                  staticClass: "btn btn-default",
                  attrs: {
                    type: "button",
                    "data-toggle": "tooltip",
                    "data-placement": "right",
                    title: "Switch to special character input",
                  },
                  on: { click: _vm.switchToChar },
                },
                [_c("i", { staticClass: "fa fa-paragraph" })]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }