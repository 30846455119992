var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("h1", [_vm._v("Edit Document Transform")]),
        _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "form",
                {
                  staticClass: "form-group",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.updateName.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("Label", { attrs: { text: "Name" } }),
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("Input", {
                        model: {
                          value: _vm.transform.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.transform, "name", $$v)
                          },
                          expression: "transform.name",
                        },
                      }),
                      _c("span", { staticClass: "input-group-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { type: "submit", disabled: _vm.savingName },
                          },
                          [
                            _vm.savingName
                              ? _c("Loader", { attrs: { stacked: false } })
                              : _vm._e(),
                            !_vm.savingName
                              ? _c("i", { staticClass: "fa fa-save" })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
          ]),
          _c("div", { staticClass: "row tag-rules" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("h2", [_vm._v("Tag Rules")]),
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "original" }, [_vm._v("Tag")]),
                    _c("th", { staticClass: "replacement" }, [
                      _vm._v("Replacement"),
                    ]),
                    _c("th", { staticClass: "actions" }),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.transform.transform_rules, function (rule) {
                    return rule.type == "tag"
                      ? _c("TagRow", { key: rule.id, attrs: { rule: rule } })
                      : _vm._e()
                  }),
                  1
                ),
              ]),
              _c("div", { staticClass: "actions text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.hasNewRecord },
                    on: {
                      click: function ($event) {
                        return _vm.addRule("tag")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v("\nAdd Rule")]
                ),
              ]),
              _c("hr"),
            ]),
          ]),
          _c("div", { staticClass: "row character-rules" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("h2", [_vm._v("Character Rules")]),
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "original" }, [
                      _vm._v("Chraracter"),
                    ]),
                    _c("th", { staticClass: "replacement" }, [
                      _vm._v("Replacement"),
                    ]),
                    _c("th", { staticClass: "actions" }),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.transform.transform_rules, function (rule) {
                    return rule.type == "character"
                      ? _c("CharacterRow", {
                          key: rule.id,
                          attrs: { rule: rule },
                        })
                      : _vm._e()
                  }),
                  1
                ),
              ]),
              _c("div", { staticClass: "actions text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button", disabled: _vm.hasNewRecord },
                    on: {
                      click: function ($event) {
                        return _vm.addRule("character")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" }), _vm._v("\nAdd Rule")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [_c("hr")]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-default",
                    attrs: { to: "/admin/transforms" },
                  },
                  [
                    _c("i", { staticClass: "fa fa-arrow-left" }),
                    _vm._v("\nBack"),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "col-md-2" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }