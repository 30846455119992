var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Saved Searches")]),
      _vm.storySavedSearchesStore.storySavedSearches.length == 0
        ? [
            _c("div", { staticClass: "alert alert-info" }, [
              _vm._v("There aren't any saved searches yet"),
            ]),
          ]
        : [_c("Table")],
      _c("hr"),
      _vm._m(0),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-center text-muted" }, [
      _c("a", { attrs: { href: "/stories" } }, [_vm._v("Search for stories")]),
      _vm._v("\nand click the\n"),
      _c("span", { staticClass: "btn btn-info btn-xs" }, [
        _c("i", { staticClass: "fa fa-search" }),
        _vm._v("\nSearch\n"),
        _c("i", { staticClass: "fa fa-caret-down" }),
      ]),
      _vm._v("\ndropdown button to save new searches"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }