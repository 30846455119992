var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DropdownCheckboxes", {
    attrs: {
      label: _vm.label,
      name: _vm.name,
      options: _vm.squeueOptions,
      value: _vm.value,
      loading: _vm.loading,
    },
    on: { input: _vm.update },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }