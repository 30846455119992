var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", [
    _c(
      "div",
      { staticClass: "panel panel-default" },
      [
        _vm.selectionType.single
          ? [
              _vm._m(0),
              _c("div", { staticClass: "panel-body" }, [_c("Details")], 1),
            ]
          : _vm._e(),
        _vm.selectionType.multiple
          ? [
              _vm._m(1),
              _c("div", { staticClass: "panel-body" }, [_c("BatchForm")], 1),
            ]
          : _vm._e(),
        _vm.selectionType.none
          ? [
              _vm._m(2),
              _c("div", { staticClass: "panel-body" }, [_c("MediumForm")], 1),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", [_vm._v("Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", [_vm._v("Batch Actions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", [_vm._v("Upload a File")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }