var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FileDropOverlay",
    {
      on: { drop: _vm.fileDropHandler },
      scopedSlots: _vm._u([
        {
          key: "description",
          fn: function () {
            return [
              _c("p", [
                _vm._v("To create a new story from the file's contents."),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }