var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-2" }),
    _c(
      "div",
      { staticClass: "col-md-8" },
      [
        _c("h1", [_vm._v("New Section Status")]),
        _c("Form", { attrs: { squeue: _vm.squeue } }),
      ],
      1
    ),
    _c("div", { staticClass: "col-md-2" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }