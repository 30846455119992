var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "story-medium" }, [
    _c("div", { staticClass: "media" }, [
      _c("div", { staticClass: "media-left" }, [
        _c(
          "a",
          {
            staticClass: "image",
            attrs: {
              href: _vm.url,
              target: "_blank",
              "data-toggle": "tooltip",
              title: _vm.mediaFileName,
            },
          },
          [
            _vm.fileType == "image"
              ? _c("img", { attrs: { src: _vm.small_thumb_url, width: "100" } })
              : _vm._e(),
            _vm.fileType != "image"
              ? _c("i", { staticClass: "fa fa-file" })
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "media-body" },
        [
          _vm.credit ? _c("Label", { attrs: { text: _vm.credit } }) : _vm._e(),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.cutline) } }),
          _vm.filename
            ? _c("a", {
                staticClass: "media-filename text-muted",
                attrs: { href: _vm.url },
                domProps: { textContent: _vm._s(_vm.mediaFileName) },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c("LabelledCheckbox", {
                attrs: { label: "Print", value: _vm.for_print },
              }),
              _c("LabelledCheckbox", {
                attrs: { label: "Web", value: _vm.for_web },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }