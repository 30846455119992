var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.actionIcon
        ? _c("span", { staticClass: "action-icon" }, [
            _c("i", {
              staticClass: "fa text-muted",
              class: [`fa-${_vm.actionIcon}`],
              on: { click: _vm.actionClicked },
            }),
          ])
        : _vm._e(),
      _vm.multiLine && _vm.html
        ? [
            _c("div", { staticClass: "panel panel-default" }, [
              _c(
                "div",
                { staticClass: "panel-body" },
                [
                  _vm._t("default", function () {
                    return [
                      _c("div", { domProps: { innerHTML: _vm._s(_vm.value) } }),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
      _vm.multiLine && !_vm.html
        ? [
            _c("div", { staticClass: "panel panel-default" }, [
              _c(
                "div",
                { staticClass: "panel-body" },
                [
                  _vm._t("default", function () {
                    return [_vm._v(_vm._s(_vm.value))]
                  }),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
      !_vm.multiLine && _vm.html
        ? [
            _c(
              "div",
              { staticClass: "vue-labelled-value-value" },
              [
                _vm._t("default", function () {
                  return [
                    _c("div", { domProps: { innerHTML: _vm._s(_vm.value) } }),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      !_vm.multiLine && !_vm.html
        ? [
            _c(
              "div",
              { staticClass: "vue-labelled-value-value" },
              [
                _vm._t("default", function () {
                  return [_vm._v(_vm._s(_vm.value))]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }