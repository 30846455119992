var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.anyExportFormats
        ? [
            _c("Label", { attrs: { text: "Export Fields" } }),
            _c("DropdownCheckboxes", {
              staticClass: "block",
              attrs: {
                label: "Export formats",
                options: _vm.exportFormatsStore.fieldsOnlyAsObject,
              },
              model: {
                value: _vm.exportFormatIds,
                callback: function ($$v) {
                  _vm.exportFormatIds = $$v
                },
                expression: "exportFormatIds",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }