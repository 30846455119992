var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clearfix" },
    [
      _vm.readOnly
        ? [
            _c("input", {
              attrs: { type: "hidden" },
              domProps: { value: _vm.inputValue },
            }),
            _vm.label ? _c("h4", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
            _c("div", { staticClass: "panel panel-default" }, [
              _c("div", {
                staticClass: "panel-body",
                style: { height: _vm.height + 20 + "px" },
                domProps: { innerHTML: _vm._s(_vm.inputValue) },
              }),
              _vm.filename || !_vm.perFieldFileMode
                ? _c(
                    "div",
                    { staticClass: "panel-footer text-right" },
                    [
                      _c("i", { staticClass: "fa fa-pencil" }),
                      _vm.filename
                        ? [
                            _vm._v(
                              "\nEdit " +
                                _vm._s(_vm.filename) +
                                " to make changes"
                            ),
                          ]
                        : [
                            _vm._v(
                              "\nEdit the content file linked to this story to change this field"
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]
        : [
            _c("RichtextEditor", {
              attrs: {
                "show-counts": _vm.showCounts,
                value: _vm.inputValue,
                label: _vm.label,
                height: _vm.height,
                loading: _vm.loading,
                classes: "form-control-lg",
              },
              on: { input: _vm.inputHandler },
            }),
          ],
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [_vm._t("default")], 2),
        _vm.perFieldFileMode
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "btn-group pull-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-sm",
                    class: { active: _vm.editHereActive },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.editWysiygHere()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-pencil-square-o" }),
                    _vm._v("\nEdit Here"),
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "btn btn-default btn-sm pull-left",
                    class: { active: _vm.editFileActive },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.editFile()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-file-text-o" }),
                    _vm._v("\nEdit File"),
                    _c("input", {
                      ref: "fileInput",
                      staticStyle: { display: "none" },
                      attrs: { type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.fileChosen($event)
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }