<template lang="pug">
form.nrq-media-filter-bar.form-inline(v-on:submit.prevent="submit")
  input.form-control(type="search" placeholder="Search" v-model="mediaSearchStore.search")
  |
  |
  div.btn-group
    button.btn.btn-default(
      type="button"
      v-on:click="mediaSearchStore.filterImages = !mediaSearchStore.filterImages"
      v-bind:class="{active: mediaSearchStore.filterImages}"
    )
      i.fa.fa-image
    button.btn.btn-default(
      type="button"
      v-on:click="mediaSearchStore.filterDocuments = !mediaSearchStore.filterDocuments"
      v-bind:class="{active: mediaSearchStore.filterDocuments}"
    )
      i.fa.fa-file-text-o
  |
  |
  SqueueCheckboxes(v-model="mediaSearchStore.squeueIds")
  |
  |
  SectionCheckboxes(v-model="mediaSearchStore.sectionIds")
  |
  |
  DateField(:disabled-dates="{from: mediaSearchStore.to}" placeholder="From" v-model="mediaSearchStore.from")
  |
  |
  DateField(:disabled-dates="{to: mediaSearchStore.from}" placeholder="To" v-model="mediaSearchStore.to")
  |
  |
  button.btn.btn-default(type="submit")
    i.fa.fa-filter
    | Filter
</template>

<script>
  import { mapStores } from 'pinia'
  import { useMediaSearchStore } from '../../../stores/media_search.js'

  import DateField from '../../../components/shared/date_field.vue'
  import SectionCheckboxes from '../../../components/shared/section_checkboxes.vue'
  import SqueueCheckboxes from '../../../components/shared/squeue_checkboxes.vue'

  export default {
    components: {
      DateField,
      SectionCheckboxes,
      SqueueCheckboxes
    },

    computed: {
      ...mapStores(useMediaSearchStore),
    },

    methods: {
      submit() {
        this.$store.dispatch('media/search', { page: 1 })
      }
    }
  }
</script>

<style lang="scss">
  .nrq-media-filter-bar {
    .input-group {
      .form-control {
        float: none;
      }
    }
  }
</style>
