var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm.styleMaps.length === 0 && !_vm.showNewForm
        ? [
            _c("div", { staticClass: "alert alert-info" }, [
              _vm._v("No style maps were found"),
            ]),
          ]
        : [
            _c("table", { staticClass: "table table-striped" }, [
              _vm._m(1),
              _c(
                "tbody",
                _vm._l(_vm.styleMaps, function (styleMap) {
                  return _c("row", {
                    key: styleMap.id,
                    attrs: { "style-map": styleMap },
                  })
                }),
                1
              ),
              _vm.showNewForm
                ? _c("tfoot", [
                    _c("tr", { staticClass: "form-group" }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.default,
                                expression: "form.default",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.form.default)
                                ? _vm._i(_vm.form.default, null) > -1
                                : _vm.form.default,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.default,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "default",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "default",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "default", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v("\nDefault"),
                        ]),
                      ]),
                      _c("td", { staticClass: "text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            on: {
                              click: function ($event) {
                                return _vm.create()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v("\nSave"),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: {
                              click: function ($event) {
                                _vm.showNewForm = false
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-ban" }),
                            _vm._v("\nCancel"),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ],
      _c("div", { staticClass: "text-right" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-default btn-success",
            attrs: { disabled: _vm.showNewForm },
            on: {
              click: function ($event) {
                _vm.showNewForm = true
              },
            },
          },
          [_c("i", { staticClass: "fa fa-plus" }), _vm._v("\nNew Style Map")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [_c("h1", [_vm._v("Style Maps")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Default")]),
        _c("th", { staticClass: "text-right" }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }