var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.edit
        ? [_c("MediumForm", { on: { save: _vm.closeEditor } })]
        : [
            _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
              _vm.isImage
                ? _c("figure", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          "data-toggle": "modal",
                          "data-target": "#medium-expanded-modal",
                        },
                      },
                      [
                        _c("ImageLoader", {
                          attrs: { imgClass: "img-responsive", src: _vm.url },
                        }),
                        _c("i", { staticClass: "expand fa fa-expand fa-2x" }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "dl",
                [
                  _c("dt", [_vm._v("Filename")]),
                  _c("dd", { staticClass: "filename" }, [
                    _vm._v(_vm._s(_vm.mediaFileName)),
                  ]),
                  _c("dt", [_vm._v("Folder")]),
                  _c("dd", { staticClass: "filepath" }, [
                    _vm._v(_vm._s(_vm.filePath)),
                  ]),
                  _c("dt", [_vm._v("Status")]),
                  _c("dd", [_vm._v(_vm._s(_vm.squeueTitle))]),
                  _c("dt", [_vm._v("Print Section")]),
                  _c("dd", [_vm._v(_vm._s(_vm.sectionTitle))]),
                  _c("dt", [_vm._v("Cutline")]),
                  _c("dd", { domProps: { innerHTML: _vm._s(_vm.cutline) } }),
                  _c("dt", [_vm._v("Credit")]),
                  _c("dd", [_vm._v(_vm._s(_vm.credit))]),
                  _c("dt", [_vm._v("Created")]),
                  _c(
                    "dd",
                    [_c("DateValue", { attrs: { value: _vm.createdAt } })],
                    1
                  ),
                  _vm.stories.length > 0
                    ? _c("dt", [_vm._v("Stories")])
                    : _vm._e(),
                  _vm._l(_vm.stories, function (story) {
                    return [
                      _c("dd", [
                        _c("a", { attrs: { href: story.url } }, [
                          _c("i", { staticClass: "fa fa-newspaper-o" }),
                          _vm._v("\n" + _vm._s(story.slug)),
                        ]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "buttons text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-default",
                    attrs: { href: _vm.downloadUrl },
                  },
                  [
                    _c("i", { staticClass: "fa fa-cloud-download" }),
                    _vm._v("\nDownload"),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.edit = true
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-pencil" }), _vm._v("\nEdit")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.destroy()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-trash" })]
                ),
              ]),
            ]),
            _vm.isImage
              ? _c(
                  "div",
                  {
                    staticClass: "modal fade",
                    attrs: { id: "medium-expanded-modal", tabindex: "-1" },
                  },
                  [
                    _c("div", { staticClass: "modal-dialog modal-lg" }, [
                      _c("div", { staticClass: "modal-content" }, [
                        _c("img", {
                          staticClass: "img-responsive img-thumbnail",
                          staticStyle: { "min-width": "100%" },
                          attrs: { src: _vm.url },
                        }),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }