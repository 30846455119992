var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-default",
      on: {
        click: function ($event) {
          return _vm.goToEdit()
        },
      },
    },
    [
      _c("i", { staticClass: "fa fa-pencil" }),
      _vm.title ? [_vm._v("\n\n" + _vm._s(_vm.title))] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }