var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      { staticClass: "publish_on" },
      [
        _c(
          "router-link",
          { attrs: { to: `/issues/${_vm.issue.id}` } },
          [_c("DateValue", { attrs: { value: _vm.issue.publish_on } })],
          1
        ),
      ],
      1
    ),
    _c("td", { staticClass: "title" }, [_vm._v(_vm._s(_vm.issue.title))]),
    _c("td", { staticClass: "slug" }, [_vm._v(_vm._s(_vm.issue.slug))]),
    _c("td", { staticClass: "publication" }, [
      _vm._v(_vm._s(_vm.issue.publication.name)),
    ]),
    _c("td", { staticClass: "story_count" }, [
      _vm._v(_vm._s(_vm.issue.story_count)),
    ]),
    _c(
      "td",
      { staticClass: "current" },
      [
        !_vm.saving
          ? _c("CheckBox", {
              attrs: { value: _vm.issue.current },
              on: { input: _vm.setCurrent },
            })
          : _vm._e(),
        _vm.saving ? _c("Loader", { attrs: { stacked: false } }) : _vm._e(),
        _vm._v(" "),
        _vm.outdatedCurrent
          ? _c("i", {
              ref: "outdatedWarning",
              staticClass: "fa fa-warning text-danger",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title:
                  "This issue is marked current but the publish date has passed",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("td", { staticClass: "actions" }, [
      _c(
        "div",
        { staticClass: "btn-group pull-right" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-default",
              attrs: { to: `/issues/${_vm.issue.id}/edit` },
            },
            [_c("i", { staticClass: "fa fa-pencil text-muted" })]
          ),
          _c("DeleteButton", {
            attrs: { loading: _vm.deleting },
            on: { click: _vm.destroy },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }