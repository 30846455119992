var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Issue")]),
      _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
        _c("form", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Slug" } }),
                  _c("Input", {
                    model: {
                      value: _vm.slug,
                      callback: function ($$v) {
                        _vm.slug = $$v
                      },
                      expression: "slug",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Title" } }),
                  _c("Input", {
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("PublicationField", {
                    attrs: { required: true },
                    model: {
                      value: _vm.publication.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.publication, "id", $$v)
                      },
                      expression: "publication.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Publish Date" } }),
                  _c("DateField", {
                    model: {
                      value: _vm.publish_on,
                      callback: function ($$v) {
                        _vm.publish_on = $$v
                      },
                      expression: "publish_on",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("Label", { attrs: { text: "Notes" } }),
                  _c("TextareaInput", {
                    model: {
                      value: _vm.notes,
                      callback: function ($$v) {
                        _vm.notes = $$v
                      },
                      expression: "notes",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-link text-muted",
                    attrs: { to: "/issues/" },
                  },
                  [_vm._v("cancel")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }