var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasValue
    ? _c(
        "div",
        { staticClass: "vue-labelled-value" },
        [
          _c("Label", { attrs: { tag: "div", text: _vm.label } }),
          _vm._l(_vm.values, function (val) {
            return _c("Value", {
              key: val,
              attrs: {
                value: val,
                "multi-line": _vm.multiLine,
                html: _vm.html,
              },
            })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }