var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagType,
    {
      tag: "component",
      staticClass: "toolbar-button",
      class: _vm.classObject,
      attrs: { type: "button", disabled: _vm.inactive, title: _vm.title },
      on: {
        click: function ($event) {
          return _vm.runAction()
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }