var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "table",
      { staticClass: "table table-striped" },
      [
        _vm._m(1),
        _c(
          "draggable",
          {
            attrs: { tag: "tbody", handle: ".handle" },
            on: { change: _vm.save },
            model: {
              value: _vm.taxonomies,
              callback: function ($$v) {
                _vm.taxonomies = $$v
              },
              expression: "taxonomies",
            },
          },
          [
            _vm._l(_vm.taxonomies, function (section) {
              return [
                _c("tr", { key: section.id }, [
                  _c("td", [
                    _c("a", {
                      attrs: { href: `/admin/sections/${section.id}/edit` },
                      domProps: { textContent: _vm._s(section.title) },
                    }),
                  ]),
                  _c("td", [
                    _c("code", {
                      domProps: { textContent: _vm._s(section.slug) },
                    }),
                  ]),
                  _c("td", [
                    _c("i", {
                      class: _vm.checkboxClass(section.system_record),
                    }),
                  ]),
                  _c("td", [
                    _c("i", { class: _vm.checkboxClass(section.enabled) }),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "handle btn btn-default btn-sm",
                        staticStyle: { cursor: "move" },
                      },
                      [_c("i", { staticClass: "fa fa-sort" })]
                    ),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("div", { staticClass: "pull-right" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-default",
            attrs: { href: "/admin/sections/new" },
          },
          [_vm._v("New Print Section")]
        ),
      ]),
      _c("h1", [_vm._v("Print Sections")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Code")]),
        _c("th", [_vm._v("System?")]),
        _c("th", [_vm._v("Enabled?")]),
        _c("th", { staticClass: "text-right" }, [_vm._v("Sort")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }