var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { staticClass: "control-label" }, [_vm._v("Byline")]),
      _c("vue-simple-suggest", {
        staticClass: "byline",
        attrs: {
          list: _vm.bylinePresetList,
          "min-length": 0,
          "filter-by-query": true,
          readonly: _vm.readOnly,
          destyled: true,
          styles: _vm.customStyles,
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _c("div", { staticClass: "row buttons-row" }, [
        _c("div", { staticClass: "col-sm-6" }, [_vm._t("default")], 2),
        _vm.perFieldFileMode
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "btn-group pull-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-sm",
                    class: { active: !_vm.useFileContent },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.editHere()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-pencil-square-o" }),
                    _vm._v("\nEdit Here"),
                  ]
                ),
                _c(
                  "label",
                  {
                    staticClass: "btn btn-default btn-sm pull-left",
                    class: { active: _vm.useFileContent },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.useFileContent = true
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-file-text-o" }),
                    _vm._v("\nEdit File"),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: { type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.fileChosen($event)
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }