var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "story-media" },
    [
      _c("MediumSelect"),
      _c(
        "FileDropOverlay",
        { on: { drop: _vm.uploadFiles } },
        [
          _c("LoaderOverlay", { attrs: { loading: _vm.saving } }, [
            _c(
              "div",
              { staticClass: "well" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("h3", [_vm._v("Attachments")]),
                  ]),
                  _c("div", { staticClass: "col-md-6 text-right" }, [
                    _c(
                      "div",
                      { staticClass: "btn-group" },
                      [
                        _c("UploadButton", { on: { change: _vm.uploadFiles } }),
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-info dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                              },
                            },
                            [_c("i", { staticClass: "fa fa-caret-down" })]
                          ),
                          _c(
                            "ul",
                            { staticClass: "dropdown-menu" },
                            [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.openMediumSelect()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-search" }),
                                    _vm._v("\nFind Media"),
                                  ]
                                ),
                              ]),
                              _vm.addableFiles.length > 0
                                ? [
                                    _c(
                                      "li",
                                      { staticClass: "dropdown-header" },
                                      [_vm._v("Add found file:")]
                                    ),
                                    _vm._l(_vm.addableFiles, function (file) {
                                      return [
                                        _c("li", [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.addUnrecognizedMedium(
                                                    file
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-file",
                                              }),
                                              _vm._v(
                                                "\n" +
                                                  _vm._s(
                                                    _vm.mediaFileName(file)
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("hr"),
                _vm.noAttachments
                  ? _c("div", { staticClass: "alert alert-info text-center" }, [
                      _vm._v("No attachments yet"),
                    ])
                  : _vm._e(),
                _c(
                  "draggable",
                  {
                    attrs: { handle: ".handle" },
                    model: {
                      value: _vm.storyMediaArray,
                      callback: function ($$v) {
                        _vm.storyMediaArray = $$v
                      },
                      expression: "storyMediaArray",
                    },
                  },
                  _vm._l(_vm.storyMediaArray, function (storyMedium) {
                    return _c(
                      "div",
                      { staticClass: "story-medium-wrapper" },
                      [
                        !_vm.editing
                          ? _c("Row", { attrs: { id: storyMedium.id } })
                          : _vm._e(),
                        _vm.editing
                          ? _c("FormRow", { attrs: { id: storyMedium.id } })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c("hr"),
                !_vm.editing
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default btn-block",
                        attrs: { disabled: _vm.noAttachments, type: "button" },
                        on: { click: _vm.edit },
                      },
                      [
                        _c("i", { staticClass: "fa fa-pencil" }),
                        _vm._v("\nEdit Attachments"),
                      ]
                    )
                  : _vm._e(),
                _vm.editing
                  ? _c("div", [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success btn-block",
                            attrs: { type: "button" },
                            on: { click: _vm.save },
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v("\nSave Attachments"),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link text-muted",
                            attrs: { type: "button" },
                            on: { click: _vm.cancel },
                          },
                          [
                            _c("i", { staticClass: "fa fa-undo" }),
                            _vm._v("\nCancel"),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }