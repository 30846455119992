var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.edit
        ? [
            _c("table", { staticClass: "table table-striped" }, [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.pages, function (page) {
                  return _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/issues/${_vm.issueId}/pages/${page.id}`,
                            },
                          },
                          [_vm._v(_vm._s(page.title))]
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(page.slug))]),
                    _c("td", [_vm._v(_vm._s(page.notes))]),
                    _c("td", [_vm._v(_vm._s(page.story_count))]),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12 text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function ($event) {
                        _vm.edit = true
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-pencil" }),
                    _vm._v("\nEdit Pages"),
                  ]
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.edit
        ? [_c("IssuePagesForm", { attrs: { "issue-id": _vm.issueId } })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _c("th", [_vm._v("Slug")]),
        _c("th", [_vm._v("Notes")]),
        _c("th", [_vm._v("Stories")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }