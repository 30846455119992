var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "story-publication",
      class: _vm.otherPublications ? "panel panel-default" : "",
    },
    [
      _vm.otherPublications
        ? [
            _c("div", { staticClass: "panel-heading" }, [
              _c(
                "label",
                { staticClass: "multiple" },
                [
                  !_vm.updating
                    ? _c("CheckboxIcon", {
                        staticClass: "check-icon",
                        attrs: { checked: _vm.checked },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toggle.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.updating
                    ? _c(
                        "span",
                        { staticClass: "loader-wrapper" },
                        [_c("Loader", { staticClass: "loader" })],
                        1
                      )
                    : _vm._e(),
                  _c("h4", [
                    _vm._v(_vm._s(_vm.publication.name)),
                    _c("small", [_vm._v("publication")]),
                  ]),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm.checkedOrShown || !_vm.otherPublications
        ? [
            _c("div", { class: _vm.otherPublications ? "panel-body" : "" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Story Template"),
                  ]),
                  !_vm.publication.hide_story_templates
                    ? _c("CommunityqStoryTemplateField", {
                        attrs: { "publication-id": _vm.publication.id },
                        model: {
                          value: _vm.storyPublication.story_template_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.storyPublication,
                              "story_template_id",
                              $$v
                            )
                          },
                          expression: "storyPublication.story_template_id",
                        },
                      })
                    : _c(
                        "select",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [
                          _c("option", { attrs: { selected: "" } }, [
                            _vm._v("disabled for this publication"),
                          ]),
                        ]
                      ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("CommunityqSubsitesField", {
                    attrs: { "publication-id": _vm.publication.id },
                    model: {
                      value: _vm.storyPublication.subsite_town_ids,
                      callback: function ($$v) {
                        _vm.$set(_vm.storyPublication, "subsite_town_ids", $$v)
                      },
                      expression: "storyPublication.subsite_town_ids",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Content Type"),
                  ]),
                  !_vm.publication.hide_content_types
                    ? _c("CommunityqContentTypeField", {
                        attrs: { "publication-id": _vm.publication.id },
                        model: {
                          value: _vm.storyPublication.content_type_key,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.storyPublication,
                              "content_type_key",
                              $$v
                            )
                          },
                          expression: "storyPublication.content_type_key",
                        },
                      })
                    : _c(
                        "select",
                        {
                          staticClass: "form-control",
                          attrs: { disabled: "" },
                        },
                        [
                          _c("option", { attrs: { selected: "" } }, [
                            _vm._v("disabled for this publication"),
                          ]),
                        ]
                      ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }