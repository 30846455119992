var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { key: _vm.transform.id }, [
    _c(
      "td",
      [
        _c(
          "router-link",
          { attrs: { to: `/admin/transforms/${_vm.transform.id}/edit` } },
          [_vm._v(_vm._s(_vm.transform.name))]
        ),
      ],
      1
    ),
    _c("td", [_vm._v(_vm._s(_vm.transform.transform_rules.length))]),
    _c("td", { staticClass: "text-right" }, [
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-default",
              attrs: { to: `/admin/transforms/${_vm.transform.id}/edit` },
            },
            [_c("i", { staticClass: "fa fa-pencil" })]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-danger",
              on: {
                click: function ($event) {
                  return _vm.destroy()
                },
              },
            },
            [
              _vm.deleting
                ? _c("Loader", { attrs: { stacked: false } })
                : _vm._e(),
              !_vm.deleting
                ? _c("i", { staticClass: "fa fa-trash" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }