var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "well well-sm top-section" }, [
    _c("div", { staticClass: "main-options" }, [
      _c(
        "div",
        { staticClass: "form-inline" },
        [
          _c(
            "div",
            { staticClass: "pull-right" },
            [
              _c(
                "button",
                {
                  ref: "clearButton",
                  staticClass: "btn btn-default",
                  attrs: {
                    type: "button",
                    disabled: !_vm.anyQueries,
                    "data-toggle": "tooltip",
                    "data-placement": "top",
                    title: "Clear search",
                  },
                  on: { click: _vm.clear },
                },
                [_c("i", { staticClass: "fa fa-undo" })]
              ),
              _vm._v(" "),
              _c("StorySearchButton"),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-prominent btn-sm",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleAdvanced()
                    },
                  },
                },
                [
                  _vm._v("Advanced\n"),
                  _c("i", {
                    staticClass: "fa",
                    class: {
                      "fa-caret-down": !_vm.showAdvanced,
                      "fa-caret-up": _vm.showAdvanced,
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("label", { attrs: { for: "text_cont" } }, [_vm._v("Search:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text_cont,
                expression: "text_cont",
              },
            ],
            staticClass: "form-control input-sm",
            attrs: { type: "search", name: "q[text_cont]" },
            domProps: { value: _vm.text_cont },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text_cont = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("SortOptions"),
          _vm._v(" "),
          _c("UserCheckboxes", {
            model: {
              value: _vm.user_id_in,
              callback: function ($$v) {
                _vm.user_id_in = $$v
              },
              expression: "user_id_in",
            },
          }),
          _vm._v(" "),
          _c("SectionCheckboxes", {
            model: {
              value: _vm.story_sections_section_id_in,
              callback: function ($$v) {
                _vm.story_sections_section_id_in = $$v
              },
              expression: "story_sections_section_id_in",
            },
          }),
          _vm._v(" "),
          _c("PublicationCheckboxes", {
            model: {
              value: _vm.story_publications_publication_id_in,
              callback: function ($$v) {
                _vm.story_publications_publication_id_in = $$v
              },
              expression: "story_publications_publication_id_in",
            },
          }),
          _vm._v(" "),
          _c("SqueueCheckboxes", {
            model: {
              value: _vm.squeue_id_in,
              callback: function ($$v) {
                _vm.squeue_id_in = $$v
              },
              expression: "squeue_id_in",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showAdvanced,
            expression: "showAdvanced",
          },
        ],
        ref: "advanced",
        staticClass: "advanced-options",
      },
      [
        _c("hr"),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "slug_cont" } }, [
                _vm._v("Slug contains"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.slug_cont,
                    expression: "slug_cont",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { type: "search", name: "q[slug_cont]" },
                domProps: { value: _vm.slug_cont },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.slug_cont = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "from-group" }, [
              _c("label", [_vm._v("Pub date")]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("DateField", {
                      attrs: { name: "q[pub_date_gteq]", placeholder: "From" },
                      model: {
                        value: _vm.pub_date_gteq,
                        callback: function ($$v) {
                          _vm.pub_date_gteq = $$v
                        },
                        expression: "pub_date_gteq",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("DateField", {
                      attrs: { name: "q[pub_date_lteq]", placeholder: "To" },
                      model: {
                        value: _vm.pub_date_lteq,
                        callback: function ($$v) {
                          _vm.pub_date_lteq = $$v
                        },
                        expression: "pub_date_lteq",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "byline_cont" } }, [
                _vm._v("Byline contains"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.byline_cont,
                    expression: "byline_cont",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { type: "search", name: "q[byline_cont]" },
                domProps: { value: _vm.byline_cont },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.byline_cont = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "pub_date_within" } }, [
                _vm._v("Pub date within"),
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.pub_date_within,
                      expression: "pub_date_within",
                    },
                  ],
                  staticClass: "form-control input-sm",
                  attrs: { type: "number", name: "q[pub_date_within]" },
                  domProps: { value: _vm.pub_date_within },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.pub_date_within = $event.target.value
                    },
                  },
                }),
                _c("span", { staticClass: "input-group-addon" }, [
                  _vm._v("days"),
                ]),
              ]),
              _c("p", { staticClass: "help-block" }, [
                _vm._v("Select a positive or negative number of days"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "content_cont" } }, [
                _vm._v("Content contains"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.content_cont,
                    expression: "content_cont",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { type: "search", name: "q[content_cont]" },
                domProps: { value: _vm.content_cont },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.content_cont = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "custom_folders_path_eq" } }, [
                _vm._v("File storage location"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.custom_folders_path_eq,
                    expression: "custom_folders_path_eq",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { type: "search", name: "q[custom_folders_path_eq]" },
                domProps: { value: _vm.custom_folders_path_eq },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.custom_folders_path_eq = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }