var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editor
    ? _c(
        "bubble-menu",
        {
          attrs: {
            editor: _vm.editor,
            shouldShow: _vm.showBubbleMenu,
            updateDelay: 0,
          },
        },
        [
          _c("div", { staticClass: "bubble-menu btn-toolbar" }, [
            _c(
              "div",
              { staticClass: "btn-group" },
              [
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Add column after",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.addColumnAfter(),
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: { icon: "mdi:table-column-plus-after" },
                    }),
                  ],
                  1
                ),
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Add column before",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.addColumnBefore(),
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: { icon: "mdi:table-column-plus-before" },
                    }),
                  ],
                  1
                ),
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Add row after",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.addRowAfter(),
                    },
                  },
                  [_c("Icon", { attrs: { icon: "mdi:table-row-plus-after" } })],
                  1
                ),
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Add row before",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.addRowBefore(),
                    },
                  },
                  [
                    _c("Icon", {
                      attrs: { icon: "mdi:table-row-plus-before" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btn-group" },
              [
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Delete row",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.deleteRow(),
                    },
                  },
                  [_c("Icon", { attrs: { icon: "mdi:table-row-remove" } })],
                  1
                ),
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Delete column",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.deleteColumn(),
                    },
                  },
                  [_c("Icon", { attrs: { icon: "mdi:table-column-remove" } })],
                  1
                ),
                _c(
                  "ToolbarButton",
                  {
                    attrs: {
                      editor: _vm.editor,
                      title: "Delete table",
                      classes: _vm.btnClasses,
                      action: (chain) => chain.deleteTable(),
                    },
                  },
                  [_c("Icon", { attrs: { icon: "mdi:table-large-remove" } })],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }