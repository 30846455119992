var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { staticClass: "control-label" }, [_vm._v("Print Section")]),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.sectionId,
            expression: "sectionId",
          },
        ],
        staticClass: "form-control",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.sectionId = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      _vm._l(_vm.sections, function (section) {
        return _c("option", { domProps: { value: section.id } }, [
          _vm._v(_vm._s(section.title)),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }