var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "edit" },
    [
      _c("CommunityQPublishModal"),
      _c("StoryEmailModal"),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveButtonAction.apply(null, arguments)
            },
          },
        },
        [
          _c("nav", { staticClass: "story-header container sticky-top" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(0),
              _c("div", { staticClass: "col-md-6 text-right" }, [
                _c(
                  "div",
                  { staticClass: "btn-group" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button", disabled: _vm.lockedToUser },
                        on: { click: _vm.saveButtonAction },
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.saveButtonClass,
                        }),
                        _vm._v("\n" + _vm._s(_vm.saveButtonText)),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-prominent btn-info",
                        on: {
                          click: function ($event) {
                            return _vm.goBack()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-arrow-left" }),
                        _vm._v("\nBack"),
                      ]
                    ),
                    _vm.exportable
                      ? [
                          _c("MoreButton", {
                            attrs: {
                              "story-id": _vm.storyId,
                              "story-name": _vm.name,
                              without: [
                                "mark-published",
                                "duplicate",
                                "delete",
                                "divider",
                              ],
                              locked: _vm.lockedToUser,
                            },
                          }),
                        ]
                      : _vm._e(),
                    !_vm.spiked
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              attrs: {
                                type: "button",
                                disabled: _vm.lockedToUser,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.spikeStory.apply(null, arguments)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("p", { staticClass: "server-path" }, [
                  _c("i", { staticClass: "fa fa-hdd-o" }),
                  _vm._v(" "),
                  _vm.openFolderUrl
                    ? _c("a", { attrs: { href: _vm.openFolderUrl } }, [
                        _vm._v(_vm._s(_vm.contentPath)),
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.contentPath))]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-6 text-right" }, [
                _c(
                  "p",
                  {
                    staticClass: "updated-at",
                    attrs: { title: "Last modified" },
                  },
                  [
                    _c("i", { staticClass: "fa fa-clock-o" }),
                    _vm._v("\n" + _vm._s(_vm.lastUpdated)),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("LockedOverlay", { attrs: { "story-id": _vm.storyId } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-7" },
                [
                  _c(
                    "LoaderOverlay",
                    { attrs: { loading: _vm.loading } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { "has-error": _vm.hasError["slug"] },
                        },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Slug\n"),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _c("Input", {
                            model: {
                              value: _vm.slug,
                              callback: function ($$v) {
                                _vm.slug = $$v
                              },
                              expression: "slug",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Overline"),
                          ]),
                          _c("SingleFileLinkedTextField", {
                            model: {
                              value: _vm.overline,
                              callback: function ($$v) {
                                _vm.overline = $$v
                              },
                              expression: "overline",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Headline"),
                          ]),
                          _c("FileContentLinkedField", {
                            staticClass: "headline-container",
                            attrs: {
                              "content-for": "headline",
                              "input-class": "input-lg",
                            },
                            model: {
                              value: _vm.headline,
                              callback: function ($$v) {
                                _vm.headline = $$v
                              },
                              expression: "headline",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Deck head"),
                          ]),
                          _c("SingleFileLinkedTextField", {
                            model: {
                              value: _vm.dropHead,
                              callback: function ($$v) {
                                _vm.dropHead = $$v
                              },
                              expression: "dropHead",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("BylineField", {
                        model: {
                          value: _vm.byline,
                          callback: function ($$v) {
                            _vm.byline = $$v
                          },
                          expression: "byline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("br"),
                        _c("FileContentLinkedWysiwyg", {
                          attrs: {
                            "content-for": "content",
                            label: "Story Body",
                            loading: _vm.loading,
                          },
                          model: {
                            value: _vm.content,
                            callback: function ($$v) {
                              _vm.content = $$v
                            },
                            expression: "content",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Tag line"),
                      ]),
                      _c("RichtextEditor", {
                        staticStyle: {},
                        attrs: {
                          height: "100",
                          loading: _vm.loading,
                          classes: "form-control-sm",
                        },
                        model: {
                          value: _vm.tag,
                          callback: function ($$v) {
                            _vm.tag = $$v
                          },
                          expression: "tag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "LoaderOverlay",
                    { attrs: { loading: _vm.loading } },
                    [
                      _c("div", { staticClass: "panel panel-default" }, [
                        _c("div", { staticClass: "panel-heading" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c(
                                "h4",
                                { staticStyle: { "margin-top": "7px" } },
                                [_vm._v("Breakout Box")]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-6 text-right" },
                              [
                                _c("ShowHideButton", {
                                  staticClass: "btn-sm",
                                  model: {
                                    value: _vm.showInfobox,
                                    callback: function ($$v) {
                                      _vm.showInfobox = $$v
                                    },
                                    expression: "showInfobox",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showInfobox,
                                expression: "showInfobox",
                              },
                            ],
                            staticClass: "panel-body",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Headline"),
                                ]),
                                _c("Input", {
                                  staticClass: "input-lg",
                                  model: {
                                    value: _vm.infoboxHeadline,
                                    callback: function ($$v) {
                                      _vm.infoboxHeadline = $$v
                                    },
                                    expression: "infoboxHeadline",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("FileContentLinkedWysiwyg", {
                                  attrs: {
                                    "content-for": "infobox_text",
                                    label: "Content",
                                    height: 150,
                                    loading: _vm.loading,
                                  },
                                  model: {
                                    value: _vm.infoboxText,
                                    callback: function ($$v) {
                                      _vm.infoboxText = $$v
                                    },
                                    expression: "infoboxText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("PullQuote", { attrs: { "story-id": _vm.storyId } }),
                      _c("div", { staticClass: "panel panel-default" }, [
                        _c("div", { staticClass: "panel-heading" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c(
                                "h4",
                                { staticStyle: { "margin-top": "7px" } },
                                [_vm._v("Web")]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-6 text-right" },
                              [
                                _c("ShowHideButton", {
                                  staticClass: "btn-sm",
                                  model: {
                                    value: _vm.showCommunityQ,
                                    callback: function ($$v) {
                                      _vm.showCommunityQ = $$v
                                    },
                                    expression: "showCommunityQ",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showCommunityQ,
                                expression: "showCommunityQ",
                              },
                            ],
                            staticClass: "panel-body",
                          },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { staticClass: "checkbox-inline" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.featured,
                                      expression: "featured",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.featured)
                                      ? _vm._i(_vm.featured, null) > -1
                                      : _vm.featured,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.featured,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.featured = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.featured = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.featured = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v("\nFeatured"),
                              ]),
                              _c("label", { staticClass: "checkbox-inline" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ispublic,
                                      expression: "ispublic",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.ispublic)
                                      ? _vm._i(_vm.ispublic, null) > -1
                                      : _vm.ispublic,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.ispublic,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.ispublic = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.ispublic = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.ispublic = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v("\nFree"),
                              ]),
                              _c("label", { staticClass: "checkbox-inline" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.commentsDisabled,
                                      expression: "commentsDisabled",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.commentsDisabled)
                                      ? _vm._i(_vm.commentsDisabled, null) > -1
                                      : _vm.commentsDisabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.commentsDisabled,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.commentsDisabled = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.commentsDisabled = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.commentsDisabled = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v("\nComments disabled"),
                              ]),
                            ]),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Alt web headline"),
                                ]),
                                _c("Input", {
                                  staticClass: "input-lg",
                                  model: {
                                    value: _vm.webHead,
                                    callback: function ($$v) {
                                      _vm.webHead = $$v
                                    },
                                    expression: "webHead",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Slug"),
                                ]),
                                _c("Input", {
                                  model: {
                                    value: _vm.webSlug,
                                    callback: function ($$v) {
                                      _vm.webSlug = $$v
                                    },
                                    expression: "webSlug",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Web lead or summary"),
                                ]),
                                _c("TextareaInput", {
                                  attrs: {
                                    name: "story[web_summary]",
                                    rows: "4",
                                  },
                                  model: {
                                    value: _vm.webSummary,
                                    callback: function ($$v) {
                                      _vm.webSummary = $$v
                                    },
                                    expression: "webSummary",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Web story content"),
                                ]),
                                _c("RichtextEditor", {
                                  staticStyle: {},
                                  attrs: {
                                    name: "story[web_body]",
                                    height: "150",
                                    loading: _vm.loading,
                                    classes: "form-control-lg",
                                  },
                                  model: {
                                    value: _vm.webBody,
                                    callback: function ($$v) {
                                      _vm.webBody = $$v
                                    },
                                    expression: "webBody",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("OnlineStatusField", {
                                    model: {
                                      value: _vm.onlinestatusId,
                                      callback: function ($$v) {
                                        _vm.onlinestatusId = $$v
                                      },
                                      expression: "onlinestatusId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "control-label" },
                                      [_vm._v("Keywords")]
                                    ),
                                    _c("Input", {
                                      model: {
                                        value: _vm.keywords,
                                        callback: function ($$v) {
                                          _vm.keywords = $$v
                                        },
                                        expression: "keywords",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("StoryPublications", {
                              attrs: { "story-id": _vm.storyId },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-5" },
                [
                  _c(
                    "LoaderOverlay",
                    { attrs: { loading: _vm.loading } },
                    [
                      _c("MediaIndex", { attrs: { "story-id": _vm.storyId } }),
                      _c("hr"),
                    ],
                    1
                  ),
                  _c("LoaderOverlay", { attrs: { loading: _vm.loading } }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Export Mode"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "btn-group btn-group-justified" },
                      [
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              ref: "fileModePerField",
                              staticClass: "btn btn-default",
                              class: { active: _vm.perFieldFileMode },
                              attrs: {
                                type: "button",
                                "data-toggle": "tooltip",
                                "data-placement": "top",
                                title: "A separate file for each content field",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.fileMode = "per_field"
                                },
                              },
                            },
                            [_vm._v("File Per Field")]
                          ),
                        ]),
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              ref: "fileModeSingle",
                              staticClass: "btn btn-default",
                              class: { active: _vm.singleFileMode },
                              attrs: {
                                type: "button",
                                "data-toggle": "tooltip",
                                "data-placement": "top",
                                title:
                                  "One file contains content for multiple fields",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.fileMode = "single"
                                },
                              },
                            },
                            [_vm._v("Single File")]
                          ),
                        ]),
                      ]
                    ),
                    _vm.singleFileMode
                      ? _c("div", { staticStyle: { "margin-top": "5px" } }, [
                          _vm.singleFilePresent
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "input-group" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "input-group-addon" },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-file-text",
                                        }),
                                      ]
                                    ),
                                    _c("Input", {
                                      attrs: {
                                        value: _vm.singleFile.medium.filename,
                                        disabled: true,
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "input-group-btn" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "btn btn-info",
                                            attrs: { type: "button" },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-cloud-upload",
                                            }),
                                            _c("input", {
                                              staticStyle: { display: "none" },
                                              attrs: { type: "file" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.singleFileChosen(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "btn-group new-single-file" },
                                  [
                                    _vm.masterTemplate
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            class: {
                                              active: _vm.generateNewSingleFile,
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.generateNewSingleFile = true
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-file-text",
                                            }),
                                            _vm._v("\nGenerate File"),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "btn btn-info",
                                        class: {
                                          active: !_vm.generateNewSingleFile,
                                        },
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            _vm.generateNewSingleFile = false
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-cloud-upload",
                                        }),
                                        _vm._v("\nAttach File"),
                                        _c("input", {
                                          staticStyle: { display: "none" },
                                          attrs: { type: "file" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.singleFileChosen(
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                        ])
                      : _vm._e(),
                    _c("hr"),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("SqueueField", {
                          staticClass: "col-md-6",
                          attrs: { required: true },
                          model: {
                            value: _vm.squeueId,
                            callback: function ($$v) {
                              _vm.squeueId = $$v
                            },
                            expression: "squeueId",
                          },
                        }),
                        _c("UserField", {
                          staticClass: "col-md-6",
                          attrs: { default: _vm.currentUser.id },
                          model: {
                            value: _vm.userId,
                            callback: function ($$v) {
                              _vm.userId = $$v
                            },
                            expression: "userId",
                          },
                        }),
                        _c("SectionsField", {
                          staticClass: "col-md-6",
                          model: {
                            value: _vm.sectionIds,
                            callback: function ($$v) {
                              _vm.sectionIds = $$v
                            },
                            expression: "sectionIds",
                          },
                        }),
                        _c("WebCategoriesField", {
                          staticClass: "col-md-6",
                          model: {
                            value: _vm.webCategoryIds,
                            callback: function ($$v) {
                              _vm.webCategoryIds = $$v
                            },
                            expression: "webCategoryIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "LoaderOverlay",
                    { attrs: { loading: _vm.loading } },
                    [
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Issues"),
                          ]),
                          _c("Placements", {
                            attrs: { "story-id": _vm.storyId },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Pub date"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-inline" },
                          [
                            _c("DateField", {
                              model: {
                                value: _vm.pubDate,
                                callback: function ($$v) {
                                  _vm.pubDate = $$v
                                },
                                expression: "pubDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("hr"),
                      _c("Locations", { attrs: { "story-id": _vm.storyId } }),
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("File storage locations"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "input-group" },
                            [
                              _c("div", { staticClass: "input-group-addon" }, [
                                _c("i", { staticClass: "fa fa-hdd-o" }),
                              ]),
                              _c("Input", {
                                attrs: {
                                  placeholder:
                                    _vm.contentPath || _vm.primaryContentPath,
                                  readonly: true,
                                },
                              }),
                              _c("div", { staticClass: "input-group-addon" }, [
                                _c("i", { staticClass: "fa fa-lock" }),
                              ]),
                            ],
                            1
                          ),
                          _c("CustomFolders", {
                            attrs: { "story-id": _vm.storyId },
                          }),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Notes"),
                          ]),
                          _c("TextareaInput", {
                            model: {
                              value: _vm.notes,
                              callback: function ($$v) {
                                _vm.notes = $$v
                              },
                              expression: "notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.currentUser.show_advert_fields
                        ? [
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Twitter Feed"),
                                ]),
                                _c("Input", {
                                  model: {
                                    value: _vm.twitterFeed,
                                    callback: function ($$v) {
                                      _vm.twitterFeed = $$v
                                    },
                                    expression: "twitterFeed",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Tweet"),
                                ]),
                                _c("Input", {
                                  model: {
                                    value: _vm.tweet,
                                    callback: function ($$v) {
                                      _vm.tweet = $$v
                                    },
                                    expression: "tweet",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Google Search Ad"),
                                ]),
                                _c("Input", {
                                  model: {
                                    value: _vm.googleSearchAd,
                                    callback: function ($$v) {
                                      _vm.googleSearchAd = $$v
                                    },
                                    expression: "googleSearchAd",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { staticClass: "control-label" }, [
                                  _vm._v("Outbrain Ad"),
                                ]),
                                _c("Input", {
                                  model: {
                                    value: _vm.outbrainAd,
                                    callback: function ($$v) {
                                      _vm.outbrainAd = $$v
                                    },
                                    expression: "outbrainAd",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("hr"),
                          ]
                        : _vm._e(),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "checkbox-inline" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.freelance,
                                expression: "freelance",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.freelance)
                                ? _vm._i(_vm.freelance, null) > -1
                                : _vm.freelance,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.freelance,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.freelance = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.freelance = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.freelance = $$c
                                }
                              },
                            },
                          }),
                          _vm._v("\nFreelance"),
                        ]),
                        _c("hr"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [_c("AutoExportSelectionsField")],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h1", [_vm._v("Edit Story")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }