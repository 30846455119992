var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("Label", { attrs: { text: _vm.label } }),
      _c("SelectInput", {
        attrs: {
          options: _vm.options,
          "blank-option-name": _vm.blankOptionName,
        },
        model: {
          value: _vm.publicationId,
          callback: function ($$v) {
            _vm.publicationId = $$v
          },
          expression: "publicationId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }