var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "btn-group" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-primary dropdown-toggle",
        attrs: { type: "button", "data-toggle": "dropdown" },
      },
      [
        _vm.loading ? _c("Loader", { attrs: { stacked: false } }) : _vm._e(),
        !_vm.loading ? _c("i", { staticClass: "fa fa-plus" }) : _vm._e(),
        _vm._v("\nNew story\n"),
        _c("span", { staticClass: "caret" }),
      ],
      1
    ),
    _c("ul", { staticClass: "dropdown-menu" }, [
      _c("li", [
        _c("a", { on: { click: _vm.createAndGoToStory } }, [
          _c("i", { staticClass: "fa fa-align-right" }),
          _vm._v("\nEmpty Form"),
        ]),
      ]),
      _c("li", [
        _c("a", [
          _c(
            "label",
            {
              staticClass: "file-label",
              on: { change: _vm.fileUploadHandler },
            },
            [
              _c("i", { staticClass: "fa fa-desktop" }),
              _c("input", {
                staticClass: "file-input",
                attrs: { type: "file" },
              }),
              _vm._v("\nFile Upload"),
            ]
          ),
        ]),
      ]),
      _c(
        "li",
        [
          _c(
            "GooglePickerButton",
            {
              attrs: { tag: "a", "mime-types": _vm.mimeTypes },
              model: {
                value: _vm.storyFileDropStore.file,
                callback: function ($$v) {
                  _vm.$set(_vm.storyFileDropStore, "file", $$v)
                },
                expression: "storyFileDropStore.file",
              },
            },
            [
              _c("i", { staticClass: "fa fa-google" }),
              _vm._v("\nGoogle Drive File"),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "hidden" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }