var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "nrq-media-filter-bar form-inline",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mediaSearchStore.search,
            expression: "mediaSearchStore.search",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "search", placeholder: "Search" },
        domProps: { value: _vm.mediaSearchStore.search },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.mediaSearchStore, "search", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            class: { active: _vm.mediaSearchStore.filterImages },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.mediaSearchStore.filterImages =
                  !_vm.mediaSearchStore.filterImages
              },
            },
          },
          [_c("i", { staticClass: "fa fa-image" })]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            class: { active: _vm.mediaSearchStore.filterDocuments },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.mediaSearchStore.filterDocuments =
                  !_vm.mediaSearchStore.filterDocuments
              },
            },
          },
          [_c("i", { staticClass: "fa fa-file-text-o" })]
        ),
      ]),
      _vm._v(" "),
      _c("SqueueCheckboxes", {
        model: {
          value: _vm.mediaSearchStore.squeueIds,
          callback: function ($$v) {
            _vm.$set(_vm.mediaSearchStore, "squeueIds", $$v)
          },
          expression: "mediaSearchStore.squeueIds",
        },
      }),
      _vm._v(" "),
      _c("SectionCheckboxes", {
        model: {
          value: _vm.mediaSearchStore.sectionIds,
          callback: function ($$v) {
            _vm.$set(_vm.mediaSearchStore, "sectionIds", $$v)
          },
          expression: "mediaSearchStore.sectionIds",
        },
      }),
      _vm._v(" "),
      _c("DateField", {
        attrs: {
          "disabled-dates": { from: _vm.mediaSearchStore.to },
          placeholder: "From",
        },
        model: {
          value: _vm.mediaSearchStore.from,
          callback: function ($$v) {
            _vm.$set(_vm.mediaSearchStore, "from", $$v)
          },
          expression: "mediaSearchStore.from",
        },
      }),
      _vm._v(" "),
      _c("DateField", {
        attrs: {
          "disabled-dates": { to: _vm.mediaSearchStore.from },
          placeholder: "To",
        },
        model: {
          value: _vm.mediaSearchStore.to,
          callback: function ($$v) {
            _vm.$set(_vm.mediaSearchStore, "to", $$v)
          },
          expression: "mediaSearchStore.to",
        },
      }),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn btn-default", attrs: { type: "submit" } },
      [_c("i", { staticClass: "fa fa-filter" }), _vm._v("Filter")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }